import { hasVModel } from "@/mixins/vmodel.js";
export default {
  inheritAttrs: false,
  mixins: [hasVModel],
  props: {
    title: {
      type: String,
      default: null,
    },
    hideToolbar: {
      type: Boolean,
      default: false,
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    buttonLoading: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
    },
    width: {
      type: String,
      default: "500",
    },
    toolbarColor: {
      type: String,
    },
  },
  methods: {
    close() {
      //this.value = false;
      this.$router.go(-1);
      //this.$emit("input", false);
    },
  },
};
