<template>
  <div>
    <TaskListItem
      v-for="task in sortTasks(overdueTasks)"
      :key="task.id"
      :task="task"
      @edit="$emit('edit', task)"
      @delete="deleteTask"
    />
    <TaskListItem
      v-for="task in sortTasks(onTimeTasks)"
      :key="task.id"
      :task="task"
      @edit="$emit('edit', task)"
      @delete="deleteTask"
    />
  </div>
</template>

<script>
import TaskListItem from "../TaskListItem";
import { injectActiveEmployee } from "@/mixins/employee";
import { get } from "lodash";

import { isBefore, parseISO } from "date-fns";
import { mapGetters } from "vuex";

export default {
  name: "IncompleteTaskList",
  components: { TaskListItem },
  mixins: [injectActiveEmployee],
  props: {
    tasks: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters("auth", ["activeEmployeeId"]),
    overdueTasks() {
      const now = new Date();
      return this.tasks.filter(
        (task) => task.dueTime && isBefore(parseISO(task.dueTime), now)
      );
    },
    onTimeTasks() {
      const now = new Date();
      return this.tasks.filter(
        (task) => !(task.dueTime && isBefore(parseISO(task.dueTime), now))
      );
    },
  },
  methods: {
    editTask() {
      return;
    },
    deleteTask() {
      return;
    },

    // o(3n)
    sortTasks(tasks) {
      var myTasks = tasks.filter(
        (task) =>
          task.completed &&
          task.completed.employee &&
          task.completed.employee.id === this.activeEmployeeId
      );
      var emptyTasks = tasks.filter(
        (task) => !task.completed || !task.completed.employee
      );
      var otherTasks = tasks.filter(
        (task) =>
          task.completed &&
          task.completed.employee &&
          task.completed.employee.id !== this.activeEmployeeId
      ); // this could be optimized by manualing looping, could create two arrays at once and save a loop.

      const parseDueTime = (time) => {
        try {
          var timeArr = time.split(":");
          return parseInt(timeArr[0]) * 60 + parseInt(timeArr[1]);
        } catch (e) {
          return 24 * 60;
        }
      };

      var subSorted = [myTasks, emptyTasks, otherTasks].map((tasks) =>
        tasks.sort((a, b) => {
          if (a.dueTime !== null && b.dueTime !== null) {
            return parseDueTime(a.dueTime) - parseDueTime(b.dueTime);
          } else {
            return a.position - b.position;
          }
        })
      );
      return [].concat(...subSorted);
    },
  },
};
</script>

<style></style>
