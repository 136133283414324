<template>
  <Dialog
    v-model="dialog"
    title="Edit shift"
    :save-loading="loading"
    :save-disabled="!valid"
    :scrollable="true"
    toolbar-color="ui lighten-3"
    @click:secondary="dialog = false"
    @input="onInput"
  >
    <template #button="{ color }">
      <v-btn
        text
        :color="color"
        :loading="loading"
        :disabled="!valid"
        @click="updateShift"
        v-text="`Update Shift`"
      />
    </template>
    <VxQuery
      :query="$options.query"
      :variables="{ id: shiftId }"
      fetch-policy="cache-and-network"
      @result="onResult"
    >
      <template #loading>
        <VxLoading />
      </template>
      <template #data="{ data: { shift } }">
        <v-container>
          <v-row no-gutters align="center" class="mb-1">
            <span class="font-weight-medium">Employee:</span>
            <employee-select
              v-model="localShift.employee"
              :value="shift.employee"
              :employees="employees"
              :disabled="notManagerOrIsWIWShift"
              show-name
            />
          </v-row>
          <v-row class="mx-0">
            <rai-date-picker
              v-model="localShift.businessDate"
              :value="shift.businessDate"
              :disabled="notManagerOrIsWIWShift"
              shrink
              label="Date"
              :min="format(new Date(), 'yyyy-MM-dd')"
            />
            <rai-time-picker-text
              v-model="localShift.scheduledStartAt"
              :value="shift.scheduledStartAt"
              class="ml-4"
              :reference-date="localShift.businessDate"
              :disabled="notManagerOrIsWIWShift"
              label="Start"
            />
            <rai-time-picker-text
              v-model="localShift.scheduledEndAt"
              :value="shift.scheduledEndAt"
              class="ml-4"
              :reference-date="localShift.businessDate"
              :disabled="notManagerOrIsWIWShift"
              label="End"
            />
          </v-row>
          <v-row no-gutters>
            <v-text-field
              v-model="localShift.notes"
              :value="shift.notes"
              name="notes"
              label="Notes"
              autocomplete="nottoday"
              :disabled="!activeEmployeeCan.seeManagerContent"
              clearable
            />
          </v-row>
          <v-row no-gutters justify="start">
            <v-checkbox v-model="isDetailed" label="Add details" />
          </v-row>
          <template v-if="isDetailed">
            <ShiftDialogAddEvent
              :reference-date="shift.businessDate"
              :min="shift.scheduledStartAt"
              :max="shift.scheduledEndAt"
              @submit="addEvent"
            />
            <shift-dialog-event-list
              :events="computedShiftEvents"
              @delete="deleteEvent"
            />
          </template>
        </v-container>
      </template>
    </VxQuery>
  </Dialog>
</template>

<script>
import Dialog from "@/components/core/FormDialogFn";
import EmployeeSelect from "@/components/util/RaiEmployeeSelect";
import RaiDatePicker from "@/components/util/RaiDatePicker";
import RaiTimePickerText from "@/components/util/RaiTimePickerText";
import ShiftDialogEventList from "./ShiftDialogEventList";
import VxQuery from "@/components/vx/VxQuery";
import VxLoading from "@/components/vx/VxLoading";
import ShiftDialogAddEvent from "@/components/daybook/ShiftDialogAddEvent";

import { hasGlobalLoaderAccess, hasSnackbarAccess } from "@/mixins/ui";
import { injectActiveEmployee } from "@/mixins/employee";
import { DELETE_SHIFT_EVENT } from "@/graphql/shift_event/mutations.gql";
import { SHIFTS_TODAY } from "@/graphql/shift/queries.gql";
import { buildUpdateShiftInputObject } from "@/graphql/shift";

import gql from "graphql-tag";
import { format, isBefore, parseISO } from "date-fns";
import ApolloCacheUpdater from "apollo-cache-updater";
import { throttle, get } from "lodash";

const loadingKey = "edit-shift";

const shiftQuery = gql`
  query SHIFT_DIALOG_EDIT($id: ID!) {
    shift(id: $id) {
      id
      employee {
        id
      }
      businessDate
      scheduledStartAt
      scheduledEndAt
      notes
      events {
        id
        title
        startAt
        endAt
      }
      whenIWorkId
    }
  }
`;
export default {
  name: "ShiftDialogEdit",
  components: {
    Dialog,
    EmployeeSelect,
    RaiDatePicker,
    RaiTimePickerText,
    ShiftDialogEventList,
    VxQuery,
    VxLoading,
    ShiftDialogAddEvent,
  },
  mixins: [hasGlobalLoaderAccess, injectActiveEmployee, hasSnackbarAccess],
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    shiftId: {
      type: String,
      default: undefined,
    },
  },
  query: shiftQuery,
  data: () => ({
    localShift: {},
    isDetailed: false,
    triggerUpdate: 0,
    originalShift: {},
  }),
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(v) {
        return this.$emit("input", v);
      },
    },
    loading: {
      get() {
        return this.isLoading(loadingKey);
      },
      set(v) {
        return false;
      },
    },
    valid() {
      return true;
    },
    updateVariables() {
      return buildUpdateShiftInputObject(this.originalShift, this.localShift, {
        input: { id: this.shiftId },
      });
    },
    computedShiftEvents() {
      return this.localShift.events
        .filter((e) => e.startAt && e.endAt && e.title)
        .sort((a, b) => {
          if (isBefore(parseISO(a.startAt), parseISO(b.startAt))) return -1;
          if (isBefore(parseISO(b.startAt), parseISO(a.startAt))) return 1;
          return 0;
        });
    },
    isWIWShift() {
      return !!get(this, "localShift.whenIWorkId");
    },
    // Should not be able to edit the employee or time of a shift if:
    // - The employee is not a manager
    // - The shift is a WIW shift
    notManagerOrIsWIWShift() {
      return [!this.activeEmployeeCan.seeManagerContent, this.isWIWShift].some(
        Boolean
      );
    },
  },
  methods: {
    format,
    close() {
      this.loaded(loadingKey);
    },
    copyShiftPropToData(shift) {
      shift
        ? (this.localShift = Object.assign({}, shift))
        : (this.localShift = {});

      shift && shift.events && shift.events.length
        ? (this.isDetailed = true)
        : (this.isDetailed = false);
    },
    async updateShift() {
      console.log("updateShift called");
      this.loading = true;

      try {
        const {
          data: { errors },
        } = await this.$apollo.mutate({
          mutation: gql`
            mutation UPDATE_SHIFT($input: UpdateShiftInputObject!) {
              updateShift(input: $input) {
                shift {
                  id
                  employee {
                    id
                  }
                  businessDate
                  scheduledStartAt
                  scheduledEndAt
                  notes
                  events {
                    id
                    title
                    startAt
                    endAt
                  }
                }
                errors
              }
            }
          `,
          variables: this.updateVariables,
        });
        console.log("mutation complete");
        // GraphQL errors
        if (errors && errors.length) {
          console.log("uh oh - errors");
          throw new Error(errors.join("; "));
        }
        console.log("Shift updated successfully!");

        // Success
        this.showSnackbar({ text: `Shift updated successfully` });
        this.onInput(false);
      } catch (error) {
        this.loading = false;
      }
      this.loading = false;
    },
    deleteEvent(event) {
      // Handle removing an event that hasn't been saved yet
      if (!event.id) {
        const idx = this.localShift.events.findIndex(
          (e) => JSON.stringify(event) === JSON.stringify(e)
        );
        if (idx === -1) return true;

        this.localShift.events = [
          ...this.localShift.events.slice(0, idx),
          ...this.localShift.events.slice(idx + 1),
        ];
        return true;
      }

      // Handle removing a database-persisted event
      this.$apollo.mutate({
        mutation: DELETE_SHIFT_EVENT,
        variables: {
          id: event.id,
        },
        update: (proxy) => {
          const updates = ApolloCacheUpdater({
            proxy,
            operator: "ANY",
            queriesToUpdate: [SHIFTS_TODAY],
            searchVariables: {},
            operation: {
              type: "REMOVE",
              remove: ({ data }) => {
                data = [...data];
                const shiftIndex = data.findIndex(
                  (shift) => shift.id === this.shiftId
                );
                if (shiftIndex > -1) {
                  const shift = { ...data[shiftIndex] };
                  data[shiftIndex] = {
                    ...shift,
                    events: shift.events.filter((e) => e.id !== event.id),
                  };
                }
                return data;
              },
            },
            mutationResult: { id: event.id, __typename: "ShiftEvent" },
          });
          if (updates) {
            this.triggerUpdate++;
            console.log("ShiftEvent removed", updates);
          }
        },
      });
    },
    // When the FormDialog emits "input, false",
    // manually go back to the previous route.
    //
    // * `this.$router.go(-1)` goes back to the
    // * lock screen for some reason. /shrug
    onInput: throttle(
      function (v) {
        if (!v) {
          this.$router.push(
            this.$router.currentRoute.path.split("/").slice(0, -2).join("/")
          );
        }
      },
      1000,
      { trailing: false }
    ),
    setOriginalShift(shift) {
      this.originalShift = { ...shift };
    },
    onResult({ data: { shift } }) {
      this.setOriginalShift(shift);
      return this.copyShiftPropToData(shift);
    },
    addEvent(event) {
      this.localShift.events = [{ ...event }, ...this.localShift.events];
      return true;
    },
  },
};
</script>
