import ListItem from "./ScheduleListItem";
import ListItemWithDetails from "./ScheduleListItemWithDetails";

export default {
  functional: true,
  inheritAttrs: false,
  render: (h, ctx) => {
    const component = ctx.props.shift.events.length
      ? ListItemWithDetails
      : ListItem;

    return h(component, {
      props: ctx.props,
      ...ctx.data,
    });
  },
};
