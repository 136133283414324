<template>
  <v-list-group
    ref="listGroup"
    class="schedule-list-item schedule-list-item--with-details"
    :append-icon="false"
  >
    <template #activator>
      <ScheduleListItem :shift="shift" />
    </template>
    <EventList class="ml-4" hide-delete :events="shift.events" />
  </v-list-group>
</template>

<style lang="scss">
// If the drawer is open, remove left padding.
@mixin event-details-ellipsis {
  content: "...";
  position: relative;
  bottom: 1.5em;
  left: 50%;
  color: #888;
}
.schedule-list .schedule-list-item.schedule-list-item--with-details {
  .v-list-group__header {
    padding-left: 0;
    padding-right: 0;
  }
  &:not(.v-list-group--active)::after {
    @include event-details-ellipsis;
  }
}
.schedule-drawer {
  &.v-navigation-drawer--mini-variant {
    .schedule-list-item.schedule-list-item--with-details:not(.v-list-group--active)::after {
      content: "";
    }
  }
  &:not(.v-navigation-drawer--mini-variant) {
    .schedule-list-item.schedule-list-item--with-details:not(.v-list-group--active)::after {
      @include event-details-ellipsis;
    }
  }
}
</style>

<script>
import Base from "./Base";
import EventList from "@/components/daybook/ShiftDialogEventList";
import ScheduleListItem from "./ScheduleListItem";

export default {
  components: {
    EventList,
    ScheduleListItem,
  },
  provide: {
    isInScheduleDrawer: true,
  },
  inject: {
    drawerBus: {
      default: undefined,
    },
  },
  extends: Base,
  watch: {
    drawerBus(v) {
      if (v) {
        this.stopListeningToDrawer(v);
        this.listenToDrawer(v);
      }
    },
  },
  mounted() {
    this.drawerBus && this.listenToDrawer(this.drawerBus);
  },
  methods: {
    listenToDrawer(drawerBus) {
      drawerBus.$on("drawer-expanded", this.close);
    },
    stopListeningToDrawer(drawerBus) {
      drawerBus.$off("drawer-expanded", this.close);
    },
    close(expanded) {
      if (!expanded) return (this.$refs.listGroup.isActive = false);
    },
  },
};
</script>
