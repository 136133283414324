<script>
import TaskList from "@/components/daybook/tasks/TaskList.vue";
import TodoListCard from "@/components/daybook/tasks/TodoListCard.vue";

export default {
  name: "TasksMobile",
  components: {
    TaskList,
    TodoListCard,
  },
  props: {
    day: {
      type: String,
      required: true,
    },
  },
};
</script>
<template>
  <v-container fluid>
    <TodoListCard />
    <TaskList />
    <slot />
  </v-container>
</template>
