<template>
  <div
    ref="self"
    class="d-inline-flex align-center whodunnit ml-n3"
    data-test-ref="whodunnit"
    :data-test-attr-completed="`${completed}`"
  >
    <v-list-item-action
      v-if="showCheckbox"
      class="no-min-width mx-2"
      style="margin-right: 0px"
    >
      <v-checkbox
        ref="checkbox"
        v-model="computedCompleted"
        class="pa-0 mx-4"
        data-test-ref="whodunnit__checkbox"
        :disabled="checkIsDisabled"
        hide-details
      />
    </v-list-item-action>
    <EmployeeSelect
      :key="employeeKey"
      :employees="employees"
      :value="employee"
      :attach="$refs.self"
      absolute
      data-test-ref="whodunnit__selector"
      :loading="loading"
      :hide-search="false"
      :assign-mode="inAssignMode"
      :disabled="employeeIsDisabled"
      :clearable="!employeeIsDisabled"
      :completed="computedCompleted"
      @input="assignEmployee"
      @clear="onClear"
      @complete="handleComplete"
    />
    <v-dialog
      v-if="dialog"
      ref="dialog"
      v-model="dialog"
      data-test-ref="whodunnit__dialog"
      persistent
    >
      <v-card
        data-test-ref="whodunnit__dialog__card"
        :data-test-attr-active="`${dialog}`"
      >
        <v-card-title class="text-h5" v-text="`Who completed this task?`" />
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="employeeOption in employees"
              :key="employeeOption.id"
              data-test-ref="whodunnit__dialog__item"
              @click="dialogCompleteTask(employeeOption)"
            >
              <v-list-item-avatar>
                <img :src="employeeOption.safeAvatarUrl" v-on="on" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="get(employeeOption, 'fullName')" />
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn ref="cancel" flat @click="hideDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EmployeeSelect from "./EmployeeSelect.vue";
import { mapGetters } from "vuex";

import { addSeconds, isBefore } from "date-fns";
import { globalCurrentTime } from "@/mixins/time";
import get from "lodash/get";
import { hasRouteDay } from "@/mixins/routerParams";

export default {
  name: "Whodunnit",
  components: { EmployeeSelect },
  mixins: [globalCurrentTime, hasRouteDay],
  disabledInterval: null,
  props: {
    // Has this task been completed?
    completed: {
      type: Boolean,
      default: false,
    },
    // The timestamp at which the task was completed
    completedAt: {
      type: Date,
      default: null,
    },
    // Active employee from teamMode
    activeEmployee: {
      type: Object,
      default: null,
    },
    // The `assigned` or `completed by` employee
    employee: {
      type: Object,
      default: null,
    },
    // List of selectable employees
    employees: {
      type: Array,
      default: null,
    },
    // A unique key used for determining loading state
    loadingKey: {
      type: String,
      default: () =>
        `whodunnit-${Math.floor(Math.random() * 1000)}-${new Date().getTime()}`,
    },
  },
  data() {
    return {
      localCompleted: this.completed,
      disabledCopy: false,
      renderKey: 0,
      dialog: false,
    };
  },
  computed: {
    ...mapGetters("ui", ["isLoading"]),
    ...mapGetters("auth", ["activeEmployeeId"]),
    // why would we use vuex for this?
    //...mapGetters("bizdate", ["getBizDate"]),
    loading() {
      return this.isLoading(this.loadingKey);
    },
    // this was to return a date object, so it should use dayDate from routerParams mixin
    bizdate() {
      return this.dayDate;
      //if (this.$route.query.d) return parse(this.$route.query.d); // why not use routerParams mixin?
      //if (this.dayDate) return this.dayDate;

      //return startOfDay(new Date());
    },
    isNotToday() {
      return this.dayIsPast || this.dayIsFuture;
      //return this.bizdate.getTime() !== startOfDay(new Date()).getTime();
    },
    inAssignMode() {
      if (!this.$route.query.assignMode) return false;
      return true;
    },
    // Used to manage the internal state of the checkbox
    computedCompleted: {
      get() {
        return this.localCompleted;
      },
      set() {
        // Copy `this.localCompleted`
        const val = this.localCompleted;
        // Let parent know the checkbox was interacted with
        this.handleCheckboxChange();
        // Use nested `$nextTick()` to trigger
        // render and make sure checkbox shows
        // the externally-controlled value
        this.$nextTick(() => {
          // Change the value to trigger a re-render
          this.localCompleted = null;
          this.$nextTick(() => {
            // Set the value back to its original
            this.localCompleted = val;
          });
        });
      },
    },
    showCheckbox() {
      return !this.computedCompleted && !this.employee && !this.inAssignMode;
    },
    bizDateIsToday() {
      return this.dayIsToday;
      // day from routerParams.js and CURRENT_DAY from time.js
      //return this.day === this.CURRENT_DAY;
      //return isSameDay(this.bizdate, new Date());
    },
    bizDateIsFuture() {
      return this.dayIsFuture;
      //return isBefore(this.CURRENT_TIME, this.dayDate);
    },
    isOutside60SecondWindow() {
      if (this.isNotToday) return true;
      return (
        this.completedAt &&
        isBefore(addSeconds(this.completedAt, 60), this.CURRENT_TIME)
      );
    },
    checkIsDisabled() {
      // Due to the reference to `this.CURRENT_TIME`, this computed property
      // could fire endlessly. `this.disabled` prevents that. It gets set
      // in two watchers, `isDisabled` & `getBizDate`.
      if (this.isNotToday || this.disabledCopy) return true;
      // if (this.computedActiveEmployeeCan().editTask) return false;
      if (this.isOutside60SecondWindow) return true;
      return false;
    },
    employeeIsDisabled() {
      if (this.dayIsFuture && this.inAssignMode) return false;
      return this.checkIsDisabled;
      //this.bizdate;
      //if (this.bizDateIsFuture) return false;
      //return this.checkIsDisabled;
    },
    employeeKey() {
      return `employee-${this.renderKey}`;
    },
  },
  watch: {
    // Make sure `props.completed` and `data.localCompleted`
    // stay in sync
    completed(v) {
      this.localCompleted = v;
    },
    bizdate() {
      this.disabledCopy = false;
      this.checkIsDisabled;
    },
    checkIsDisabled(v) {
      this.disabledCopy = v;
    },
  },
  methods: {
    get,
    handleComplete(e) {
      this.computedCompleted = !this.computedCompleted;
    },
    handleCheckboxChange() {
      // If unticking the box, emit `clear`
      if (this.completed) {
        this.$celebrate(5, "٭", "gold", event.target);
        return this.$emit("clear", { loadingKey: this.loadingKey });
      }
      // If an employee is already assigned, emit
      // `complete` and pass the selected employee
      // If there is an active teamMode employee,
      // emit `complete` and pass the employee.

      // This is now - if there is an active employee pass it.
      this.$celebrate(5, "٭", "gold", event.target);
      return this.$emit("complete", {
        loadingKey: this.loadingKey,
        id: this.activeEmployeeId,
      });
    },
    // Emit `assign` and pass the employee
    assignEmployee(employee) {
      this.$emit("assign", { loadingKey: this.loadingKey, ...employee });
    },
    // Emit `clear`
    onClear() {
      this.$emit("clear-employee", { loadingKey: this.loadingKey });
    },
    showDialog() {
      this.dialog = true;
    },
    hideDialog() {
      this.dialog = false;
    },
    // Completes the task as the clicked employee
    dialogCompleteTask(employee) {
      this.$emit("complete", { loadingKey: this.loadingKey, ...employee });
      this.hideDialog();
    },
  },
};
</script>
<style>
.rai-employee-selector .v-select {
  padding-top: 0;
  margin-top: 0;
}
.v-list__tile__action.no-min-width {
  min-width: 0;
}
</style>
