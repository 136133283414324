<template>
  <v-card v-bind="$attrs" class="pa-0">
    <v-card-title
      data-test-ref="task-list__name"
      class="pa-0"
      @click="toggleCollapse"
    >
      <v-subheader>
        <v-icon size="17" class="mr-1" v-text="collapseIcon" />
        {{ name }}
      </v-subheader>
      <!-- <v-subheader
        v-if="overdue"
        class="error--text pl-1"
        v-text="`${overdue} overdue`"
      />-->
      <v-spacer />
      <span class="text-caption rai-data text--secondary pr-3">
        {{ statsDisplay }}
      </span>
    </v-card-title>
    <v-divider />

    <v-card-text class="py-0 pb-1">
      <v-slide-y-transition>
        <v-list
          v-if="!collapsed"
          class="task-list"
          data-test-ref="task-list"
          transition="scale-transition"
        >
          <IncompleteTasks :tasks="incompleteTasks" @edit="editTask" />
          <CompletedTasks
            :tasks="completedTasks"
            :complete="listIsComplete"
            @edit="editTask"
          />
        </v-list>
      </v-slide-y-transition>
    </v-card-text>
  </v-card>
</template>
<script>
import CompletedTasks from "./CompletedTasks";
import IncompleteTasks from "./IncompleteTasks";
import { hasGlobalLoaderAccess, hasSnackbarAccess } from "@/mixins/ui";
import { injectActiveEmployee } from "@/mixins/employee";
import { get } from "lodash";
import { mapGetters, mapMutations } from "vuex";
import { DELETE_TODO } from "@/graphql/todo/mutations.gql";
import { ACTIVE_TASK_LISTS, TASK_CHECKS } from "@/graphql/task/queries.gql";

import { format, startOfDay, parseISO } from "date-fns";
import { hasRouteDay } from "@/mixins/routerParams";

export default {
  components: { CompletedTasks, IncompleteTasks },
  mixins: [
    hasGlobalLoaderAccess,
    hasSnackbarAccess,
    injectActiveEmployee,
    hasRouteDay,
  ],
  inheritAttrs: false,

  props: {
    taskList: {
      type: Object,
      default: () => ({}),
    },
  },

  data: (vm) => ({
    collapsed: vm.listIsComplete,
    showCompleted: false,
  }),

  computed: {
    // TODO: pull todos out of tasks and make into separate thing
    ...mapGetters("bizdate", ["getBizDate"]),

    collapseIcon() {
      return (
        (this.collapsed && `$vuetify.icons.plus`) || `$vuetify.icons.minus`
      );
    },

    // Helper for `taskList.name`
    name() {
      return get(this.taskList, "name");
    },

    // Total number of tasks in the list
    numTotalTasks() {
      return this.activeTasks.length;
    },

    // Number of complete tasks in the list
    numCompleteTasks() {
      return this.completedTasks.length;
    },

    completedTasks() {
      return this.activeTasks.filter(
        (task) => !!task.completed && !!task.completed.completedAt
      );
    },

    incompleteTasks() {
      return this.activeTasks.filter(
        (task) => !task.completed || !task.completed.completedAt
      );
    },

    statsDisplay(todos) {
      if (todos.length === 0) {
        return "--";
      } else {
        return `${this.stats}%`;
      }
    },

    // Complete / Total
    stats() {
      return Math.round((this.numCompleteTasks / this.numTotalTasks) * 100);
    },

    // number of overdue tasks
    overdue() {
      return get(this.taskList, "tasks", []).filter(
        ({ dueTime }) => dueTime && new Date(dueTime) < new Date()
      ).length;
    },

    // Used in the mutation under `deleteTodo`
    cacheQueryVariables() {
      return {
        businessDate: this.getBizDate || format(new Date(), "yyyy-MM-dd"),
        storeId: this.$route.params.storeId,
      };
    },

    bizDate() {
      // TODO - remove from here, needed in params may only
      return startOfDay(parseISO(this.day));
    },
    currentDate() {
      if (this.$route.query.d) return parseISO(this.$route.query.d);
      return this.today;
    },
    today() {
      return startOfDay(new Date());
    },

    listIsComplete() {
      if (this.numTotalTasks === 0) return false;

      return this.numTotalTasks === this.numCompleteTasks;
    },
    completeTasks() {
      return get(this.taskList, "tasks", []).filter(
        ({ completed }) => !!completed && !!completed.completedAt
      ).length;
    },
    activeTasks() {
      return (
        this.filterManagerOnly(this.taskList.tasks || [])
          // filters out unactive tasks
          .filter((task) => {
            const taskStartDate = task.startDate
              ? parseISO(task.startDate)
              : null;
            const taskEndDate = task.endDate ? parseISO(task.endDate) : null;

            const taskStartsAfterBizDate =
              taskStartDate && taskStartDate > this.bizDate;
            const taskEndsBeforeBizDate =
              taskEndDate && taskEndDate < this.bizDate;

            return (
              !taskStartsAfterBizDate &&
              !taskEndsBeforeBizDate &&
              this.isActiveToday(task, format(this.bizDate, "iiii"))
            );
          })
      );
    },
  },
  watch: {
    numCompleteTasks() {
      this.toggleIfComplete();
    },
  },
  // On mount, collapse the
  async mounted() {
    await this.$nextTick();
    this.toggleIfComplete();
  },

  methods: {
    ...mapMutations("ui", ["loading", "loaded"]),

    // check if given task is true for given day
    isActiveToday(item, day) {
      var day_dict = {
        Monday: item.monday,
        Tuesday: item.tuesday,
        Wednesday: item.wednesday,
        Thursday: item.thursday,
        Friday: item.friday,
        Saturday: item.saturday,
        Sunday: item.sunday,
      };

      if (item.__typename !== "Todo") {
        return day_dict[day];
      } else return true;
    },
    // Self explanatory
    toggleIfComplete() {
      this.listIsComplete && this.toggleCollapse();
    },

    // Self explanatory
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },

    // Generate a delete loading key for a task in the global loader
    deleteLoadingKey(task) {
      return `delete-todo-${task.id}`;
    },

    // Emit an edit event if the employee has access to edit
    editTask(task) {
      if (!this.activeEmployeeCan.editTask) return false;

      return this.$emit("edit", task);
    },

    // Delete mutation if employee hass access to delete
    async deleteTodo(todo) {
      if (!this.activeEmployeeCan.deleteTask) return false;

      this.loading(this.deleteLoadingKey(todo));
      const variables = this.cacheQueryVariables;

      try {
        await this.$apollo.mutate({
          mutation: DELETE_TODO,
          variables: {
            id: todo.id,
          },
          optimisticResponse: {
            __typename: "DeletePayload",
            deleteTodo: {
              __typename: "DeleteTodo",
              todo: { __typename: "Todo", id: todo.id },
              errors: [],
            },
          },
          update(
            store,
            {
              data: {
                deleteTodo: {
                  todo: { id },
                  errors,
                },
              },
            }
          ) {
            const data = store.readQuery({
              query: ACTIVE_TASK_LISTS,
              variables,
            });
            const todos = data && data.todos;
            if (!todos) return;

            const index = todos.findIndex((t) => t.id === id);
            if (index < 0) return;

            const newTodos = [
              ...todos.slice(0, index),
              ...todos.slice(index + 1),
            ];

            data.todos = newTodos;
            store.writeQuery({
              query: ACTIVE_TASK_LISTS,
              variables,
              data,
            });
          },
        });
        this.showSnackbar({
          text: `Removed todo successfully`,
        });
      } catch (error) {
        this.showSnackbar({
          text: `An error occurred while deleting this todo: ${error}`,
        });
      }
      return this.loaded(this.deleteLoadingKey(todo));
    },
    // TODO: handle manager content
    filterManagerOnly(tasks) {
      if (this.activeEmployeeCan.seeManagerContent) return tasks;

      return tasks.filter(({ isManagerOnly }) => !isManagerOnly);
    },
  },
};
</script>
