<template>
  <v-slide-y-transition group>
    <IncompleteTodo
      v-for="todo in sortedTodos"
      :key="todo.id"
      :todo="todo"
      @edit="onEdit"
      @delete="deleteTodo"
    />
  </v-slide-y-transition>
</template>

<script>
import IncompleteTodo from "./IncompleteTodo";

import { isToday, isBefore, parseISO } from "date-fns";
import {
  injectActiveEmployee,
  hasActiveEmployeeIdAccess,
} from "@/mixins/employee";

export default {
  name: "IncompleteTodoList",
  components: { IncompleteTodo },
  mixins: [injectActiveEmployee, hasActiveEmployeeIdAccess],
  props: {
    todos: {
      type: Array,
      required: true,
    },
  },
  computed: {
    sortedTodos() {
      // Reduce to only iterate once
      const { myTasks, emptyTasks, otherTasks } = this.todos.reduce(
        (acc, todo) => {
          // My tasks
          if (todo.employee && todo.employee.id === this.activeEmployeeId) {
            acc.myTasks.push(todo);
            return acc;
          }
          // Empty tasks
          if (!todo.employee) {
            acc.emptyTasks.push(todo);
            return acc;
          }
          // Other tasks
          if (todo.employee && todo.employee.id !== this.activeEmployeeId) {
            acc.otherTasks.push(todo);
            return acc;
          }
          return acc;
        },
        { myTasks: [], emptyTasks: [], otherTasks: [] }
      );

      return [
        ...this.sortByDate(myTasks),
        ...this.sortByDate(emptyTasks),
        ...this.sortByDate(otherTasks),
      ];
    },
  },
  methods: {
    sortByDate(todos) {
      var today = [];
      var noDue = [];
      var dueLater = [];
      todos.forEach((todo) => {
        if (todo.dueAt === null) {
          noDue.push(todo);
        } else if (
          isToday(parseISO(todo.dueAt)) ||
          isBefore(parseISO(todo.dueAt), new Date())
        ) {
          today.push(todo);
        } else {
          dueLater.push(todo);
        }
      });
      return [...today, ...noDue, ...dueLater];
    },
    onEdit(task) {
      return this.$emit("edit", task);
    },
    deleteTodo(todo) {
      return this.$emit("delete", todo);
    },
  },
};
</script>
