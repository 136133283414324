<script>
import {
  DAY_NOTES,
  NOTE_CREATED_SUB,
  NOTE_UPDATED_SUB,
} from "@/graphql/note/queries.js";
import NoteCard from "@/components/daybook/NoteCard.vue";
import SkeletonNoteCard from "@/components/daybook/SkeletonNoteCard";
import VxQuery from "@/components/vx/VxQuery";
import { injectActiveEmployee } from "@/mixins/employee";
import { hasRouteDay, hasChildDialog } from "@/mixins/routerParams";

import { isWithinInterval, subDays, startOfDay, parseISO } from "date-fns";
import findIndex from "lodash/findIndex";
import orderBy from "lodash/orderBy";

export default {
  name: "Notes",
  components: {
    NoteCard,
    VxQuery,
    SkeletonNoteCard,
  },
  mixins: [injectActiveEmployee, hasRouteDay, hasChildDialog],

  DAY_NOTES,
  NOTE_CREATED_SUB,
  NOTE_UPDATED_SUB,

  props: {
    notes: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    dialog: false,
    allDialog: false,
    selectedNote: null,
    offsetMult: 0,
    moreLoading: false,
  }),

  computed: {
    shouldSkipQuery() {
      return !this.$route.params.storeId;
    },
    storeId() {
      return this.$route.params.storeId;
    },
  },

  watch: {
    dialog(v, o) {
      if (!v) this.selectedNote = null;
    },
  },

  methods: {
    editNote(note) {
      if (!this.activeEmployeeCan.editNote) return false;

      const toRoute = this.dialogRoute({
        name: "editNoteDialog",
        params: { noteId: note.id },
      });

      this.$router.push(toRoute);
    },
    async viewMore() {
      try {
        await this.$apollo.query({
          query: MORE_NOTES,
          variables: {
            storeId: this.storeId,
            businessDate: this.day,
            limit: this.offsetMult,
          },
        });
        this.offsetMult += 1;
      } catch (err) {
        console.log(err);
      }
    },
    /**
     * @param {Object} previousResult - The previous query result
     * @param {Object} update - The subscription update
     * @param {Object[]} previousResult.notes - The notes node on the previous query result
     * @param {Object} update.subscriptionData.data.noteCreated - The actual created note
     */
    onSubNoteCreated(previousResult, { subscriptionData }) {
      const note = subscriptionData.data.noteCreated;

      // Checks to make sure the date makes sense
      if (!this.noteDateIsShowable(note)) return previousResult;
      // Checks to make sure the note isn't already in the list
      if (findIndex(previousResult.notes, { id: note.id }) > -1)
        return previousResult;

      // The previous result is immutable, so make a copy
      const newResult = {
        notes: [...previousResult.notes],
      };

      newResult.notes.push(subscriptionData.data.noteCreated);
      return newResult;
    },
    /**
     * @param {Object} note - The note to test
     * @param {string} note.endDate - The note's endDate, if any
     * @param {string} note.businessDate - The note's businessDate, or start date
     */
    noteDateIsShowable({ endDate, businessDate }) {
      const today = new Date();
      const fiveDaysAgo = startOfDay(subDays(today, 5));

      if (
        endDate &&
        isWithinInterval(today, {
          start: parseISO(businessDate),
          end: parseISO(endDate),
        })
      ) {
        return true;
      }
      if (
        isWithinInterval(parseISO(businessDate), {
          start: fiveDaysAgo,
          end: today,
        })
      )
        return true;

      return false;
    },
    sortNotes(notes) {
      return orderBy(
        notes,
        [
          ({ businessDate }) => new Date(businessDate),
          ({ updatedAt }) => new Date(updatedAt),
        ],
        ["desc", "desc"]
      );
    },
  },
};
</script>

<template>
  <div data-testid="notes">
    <VxQuery
      :query="$options.DAY_NOTES"
      :variables="{
        storeId: storeId,
        businessDate: day,
      }"
      :skip="shouldSkipQuery"
      tag="div"
    >
      <template #subscriptions>
        <ApolloSubscribeToMore
          :document="$options.NOTE_CREATED_SUB"
          :update-query="onSubNoteCreated"
        />
        <ApolloSubscribeToMore :document="$options.NOTE_UPDATED_SUB" />
      </template>
      <template #loading>
        <SkeletonNoteCard />
      </template>
      <template #error="{ error }">
        <!-- TODO: looks terrible, fix later -->
        <span v-text="error.graphQLErrors.map((e) => e.message).join(`\n`)" />
      </template>
      <template #data="{ data: { notes: tmpNotes } }">
        <NoteCard
          v-for="note in sortNotes(tmpNotes)"
          :key="note.id"
          :note="note"
          expanded
          @edit="editNote"
        />
        <v-row class="justify-center" no-gutters>
          <v-col cols="4">
            <v-btn
              large
              block
              text
              color="primary"
              style="margin-bottom: 50px"
              :to="{ name: 'allNotesDialog' }"
              >View All</v-btn
            >
          </v-col>
        </v-row>
      </template>
      <template #emptyContent>
        <v-col cols="12">
          <v-row class="justify-center mt-12">
            <v-icon x-large>$noNotes</v-icon>
          </v-row>
        </v-col>
        <v-col cols="8" class="mt-6 ml-12 py-0">
          <p class="text-h6 font-weight-medium mb-0">What's going on today?</p>
        </v-col>
        <v-col cols="8" class="mt-6 ml-12 py-0">
          <p class="body font-weight-regular text--secondary">
            Make important announcements, share what happened today, or give a
            teammate a shoutout!
          </p>
        </v-col>
        <v-col cols="12" class="mt-12 pt-12">
          <v-row class="justify-end px-6">
            <v-btn
              text
              color="primary"
              class="mx-2"
              :to="{ name: 'allNotesDialog' }"
              >View all</v-btn
            >
            <v-btn
              depressed
              color="primary"
              class="mx-2"
              :to="{ name: 'newNoteDialog' }"
              >Add a note</v-btn
            >
          </v-row>
        </v-col>
      </template>
    </VxQuery>
    <!-- TODO: add to a route dialog -->
  </div>
</template>

<style>
.v-skeleton-loader__button {
  width: auto !important;
}
</style>
