<script>
import TaskListItem from "../TaskListItem";
import { injectActiveEmployee } from "@/mixins/employee";
import { mapGetters } from "vuex";

export default {
  name: "CompletedTaskList",
  components: {
    TaskListItem,
  },
  mixins: [injectActiveEmployee],
  props: {
    tasks: {
      type: Array,
      required: true,
    },
    complete: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      visible: this.complete,
    };
  },
  computed: {
    ...mapGetters("auth", ["activeEmployeeId"]),
    sortedCompleted() {
      var sortedTasks = [];

      this.tasks.forEach((task) => {
        if (task.completed.employee.id === this.activeEmployeeId) {
          sortedTasks.unshift(task);
        } else {
          sortedTasks.push(task);
        }
      });

      return sortedTasks;
    },
  },
  methods: {
    deleteTask() {
      return;
    },
    editTask() {
      return;
    },
  },
};
</script>

<template>
  <div>
    <template v-if="visible">
      <TaskListItem
        v-for="task in sortedCompleted"
        :key="task.id"
        :task="task"
        @edit="$emit('edit', task)"
        @delete="deleteTask"
      />
      <v-row class="justify-left ml-6 mt-2">
        <v-btn
          v-if="!complete"
          ref="hideBtn"
          text
          color="primary"
          style="text-transform: none"
          small
          @click="visible = false"
        >
          Hide completed
        </v-btn>
      </v-row>
    </template>
    <v-row v-else-if="tasks.length > 0" class="justify-left ml-6 mt-2">
      <v-btn
        ref="showBtn"
        text
        color="primary"
        style="text-transform: none"
        small
        @click="visible = true"
      >
        Show {{ tasks.length }} completed
      </v-btn>
    </v-row>
  </div>
</template>

<style></style>
