<template>
  <v-text-field
    v-model="localValue"
    :class="`add-todo-field ${!isFlat && localValue.length < 1 ? 'ml-10' : ''}`"
    data-test-ref="new-todo"
    placeholder="Add a task"
    solo
    :flat="isFlat && localValue.length < 1"
    @keyup.enter="$emit('submit')"
    @focus="isFlat = false"
    @blur="isFlat = true"
  >
    <template v-if="loading" slot="append">
      <v-progress-circular indeterminate />
    </template>
  </v-text-field>
</template>
<script>
export default {
  name: "TaskListAddTodoField",
  props: {
    value: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isFlat: true,
  }),
  computed: {
    isLoading() {
      return this.loading;
    },
    localValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
<style>
.v-text-field.add-todo-field .v-input__control .v-input__slot {
  border-radius: 24px;
}
.v-text-field.add-todo-field .v-text-field__slot {
  padding-left: 0.5em;
}
</style>
