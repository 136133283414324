<template>
  <v-list>
    <event-list-item
      v-for="event in events"
      :key="JSON.stringify(event)"
      :event="event"
      :hide-delete="hideDelete"
      @delete="onDelete"
    />
  </v-list>
</template>

<script>
import EventListItem from "./ShiftDialogEventListItem";

import { format, parseISO } from "date-fns";
export default {
  name: "ShiftDialogEventList",
  components: {
    EventListItem,
  },
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    time() {
      return (event) =>
        `${format(parseISO(event.startAt), "h:mma")} - ${format(
          parseISO(event.endAt),
          "h:mma"
        )}`;
    },
  },
  methods: {
    onDelete(event) {
      return this.$emit("delete", event);
    },
  },
};
</script>
