<template>
  <v-overlay opacity="0.02" light>
    <v-container fluid class="fill-height vx-loading">
      <v-row
        class="fill-height"
        align="center"
        align-content="center"
        justify="space-around"
      >
        <slot>
          <v-progress-circular
            indeterminate
            size="48"
            width="2"
            color="accent"
            class="ma-auto"
          />
        </slot>
      </v-row>
    </v-container>
  </v-overlay>
</template>

<script>
export default {
  name: "VxLoading",
};
</script>
