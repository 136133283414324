<script>
import { hasRouteDay } from "@/mixins/routerParams";

export default {
  name: "DayKpiText",
  mixins: [hasRouteDay],
  props: {
    prefix: {
      type: String,
      default: null,
    },
    data: {
      type: [String, Number],
      default: 0,
    },
    suffix: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
  },
  computed: {
    computedClasses() {
      var size = this.$vuetify.breakpoint.smAndDown
        ? "text-h2 text-center my-4"
        : "text-h4";
      return ["rai-data", size, this.color || "info--text"];
    },
    formattedData() {
      if (this.data === null) return "0";
      return this.data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<template>
  <div :class="computedClasses">
    <small v-if="prefix" v-text="prefix" />
    <span v-text="formattedData" />
    <small v-if="suffix" v-text="suffix" />
  </div>
</template>
