<template>
  <v-dialog
    v-bind="$attrs"
    :width="width"
    class="rai-form-dialog"
    :style="cardStyle"
    v-on="$listeners"
  >
    <template #activator="{ on }">
      <slot name="activator" :on="on" />
    </template>
    <v-card tile :style="cardStyle">
      <slot name="header">
        <v-toolbar v-if="!hideToolbar" flat :color="toolbarColor">
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
      </slot>
      <v-card-text class="pa-0 default-slot" :style="cardContentStyle">
        <slot></slot>
      </v-card-text>
      <template v-show="shouldShowCardActions()">
        <v-divider />
        <v-card-actions v-show="shouldShowCardActions()">
          <v-spacer />
          <slot name="secondaryButton" />
          <!--
            When using FormDialog, be sure to use `slotProps.color` on the v-btn color.
            This ensures that the color is 'accent' on desktop and 'white' on mobile.

            Example:
              <template #button="{ color }">
                <v-btn :color="color" ... />
              </template>
          -->
          <slot name="button" :color="'accent'">
            <v-btn
              text
              color="accent"
              :disabled="buttonDisabled"
              :loading="buttonLoading"
              @click="$emit('click:save')"
              >{{ buttonText }}</v-btn
            >
          </slot>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import FormDialogBase from "./FormDialogBase";

export default {
  name: "FormDialogDesktop",
  extends: FormDialogBase,
  inheritAttrs: false,
  computed: {
    // total vertical margin on v-main
    mainMargin() {
      var maxHeight = this.$vuetify.application.top * 2;
      return maxHeight;
    },
    cardStyle() {
      return {
        minHeight: this.$attrs.minHeight || "500px",
        height: this.$attrs.height || "70vh",
        maxHeight: this.$attrs.maxHeight || "90vh",
      };
    },
    cardContentStyle() {
      //if (this.$attrs.scrollable) {
      var height = `calc(100vh - 56px - 56px})`;
      return {
        height: height,
        minHeight: height,
        maxHeight: height,
      };
      //}
      //return {};
    },
  },
  methods: {
    // This is a method so it is always re-evaluated. As a computed property,
    // this didn't get triggered often enough.
    shouldShowCardActions() {
      return (
        (this.$slots.button && this.$slots.button()) ||
        (this.$slots.secondaryButton && this.$slots.secondaryButton()) ||
        (this.$scopedSlots.button && this.$scopedSlots.button()) ||
        (this.$scopedSlots.secondaryButton &&
          this.$scopedSlots.secondaryButton())
      );
    },
  },
};
</script>
