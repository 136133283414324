<template>
  <div
    class="rai-employee-menu"
    style="display: flex; height: 100%"
    align-center
  >
    <!-- If loading, show a progress indicator -->
    <template v-if="isLoading">
      <v-list-item
        :style="`padding-right: 0px; ${completed ? '' : 'padding-left:10px;'}`"
      >
        <v-avatar :size="size">
          <v-progress-circular indeterminate />
        </v-avatar>
      </v-list-item>
    </template>
    <!-- Otherwise, show the menu selector -->
    <template v-else>
      <v-menu
        ref="menu"
        v-model="menu"
        v-bind="$attrs"
        :disabled="disabled || !assignMode"
        :close-on-content-click="false"
        content-class="employee-select-list"
      >
        <template #activator="{ on }">
          <v-list-item
            :style="`padding-right: 0px; padding-left: 0px;`"
            :ripple="false"
            :disabled="disabled"
          >
            <!-- If we have a value, show the employee avatar -->
            <template v-if="value">
              <v-tooltip top>
                <!-- The actual employee avatar -->
                <template #activator="{ on: tooltipOn }">
                  <EmployeeAvatar
                    :employee-id="value.id"
                    :size="size"
                    :disabled="employeeAvatarDisabled"
                    class="mr-1 ml-1"
                    data-testid="employeeSelectAvatar"
                    v-on="Object.assign(on, tooltipOn)"
                    @click="handleAvatarClick"
                  />
                </template>
                <!-- Tool tip text -->
                <span v-text="selectedEmployeeName" />
              </v-tooltip>
              <!-- The employee name, if showName === true -->
              <v-list-item-content
                v-if="showName"
                class="ml-4"
                v-on="on"
                v-text="selectedEmployeeName"
              />
            </template>
            <!-- end of EmployeeAvatar -->
            <!-- Dropdown icon -->
            <v-list-item-action
              v-if="!value && assignMode"
              class="no-min-width mx-2"
              v-on="on"
            >
              <v-icon size="20" v-text="`$assignTask`" />
            </v-list-item-action>
          </v-list-item>
        </template>
        <v-list ref="list">
          <!--
            Show the currently selected employee at the top of the list,
            with a "clearable" icon
          -->
          <template v-if="value">
            <v-list-item
              v-if="value"
              class="px-5 pt-0 pb-2 my-0 v-list-item--active"
              color="accent"
            >
              <EmployeeAvatar
                :employee-id="value.id"
                list
                class="mr-3"
                size="40"
              />
              <v-list-item-content>
                <v-list-item-title v-text="name(value)" />
              </v-list-item-content>
              <v-list-item-action v-if="clearable">
                <v-btn icon text x-small @click="onClear">
                  <v-icon>$vuetify.icons.clear</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider />
          </template>
          <v-list-item v-if="showFilter">
            <v-text-field
              ref="search"
              v-model="search"
              v-intersect="onMenuOpen"
              autofocus
              placeholder="Name, email, intials"
              autocomplete="nope"
              clearable
            />
          </v-list-item>
          <v-list-item
            v-for="emp in filteredEmployees"
            :key="emp.id"
            style="padding: 5px 20px"
            @click="handleSelect(emp)"
          >
            <EmployeeAvatar :employee-id="emp.id" list size="40" class="mr-3" />
            <v-list-item-content>
              <v-list-item-title
                data-test-ref="rai-employee-menu__item-name"
                v-text="name(emp)"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </div>
</template>
<script>
import { iget } from "@/utils/lib";
import { partition, orderBy } from "lodash";
import gql from "graphql-tag";
import EmployeeAvatar from "@/components/employees/Avatar.vue";
export default {
  name: "RaiEmployeeSelect",
  components: { EmployeeAvatar },
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      default: null,
    },
    completed: {
      type: Boolean,
      default: false,
    },
    employees: {
      type: Array,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showName: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [Number, String],
      default: "32",
    },
    hideSearch: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    assignMode: {
      type: Boolean,
      default: false,
    },
  },
  apollo: {
    employee: {
      query: gql`
        query EMPLOYEE_SELECT($id: ID!) {
          employee(id: $id) {
            id
            fullName
            avatarUrl
          }
        }
      `,
      variables() {
        return {
          id: this.value && this.value.id,
        };
      },
      fetchPolicy: "cache-first",
      skip() {
        return !(this.value && this.value.id);
      },
    },
  },
  data: () => ({
    employee: {},
    search: undefined,
    menu: false,
  }),
  computed: {
    employeeAvatarDisabled() {
      return this.disabled || this.assignMode;
    },
    // Returns the index of `this.value` in `employees`
    // so it can be removed.
    selectedEmployeeIndex() {
      if (!this.value) return -1;
      return (this.employees || []).findIndex((e) => e.id === this.value.id);
    },
    // Does `this.computedEmployees` include `this.value`?
    // Returns true if `this.value` is falsy
    // Otherwise, evaluates normally
    computedEmployeesContainsSelectedEmployee() {
      // If `this.value` is falsy, we want to return `true`
      // so it's not included in `this.computedEmployees`
      if (!this.value) return false;
      return this.selectedEmployeeIndex >= 0;
    },
    // Returns computed employees, excluding `this.value`.
    computedEmployees() {
      const employees = this.employees || [];
      if (!this.computedEmployeesContainsSelectedEmployee) return employees;
      return employees
        .slice(0, this.selectedEmployeeIndex)
        .concat(employees.slice(this.selectedEmployeeIndex + 1));
    },
    employeesOrderedByAction() {
      const parts = partition(this.computedEmployees, (e) => !!e.lastActionAt);
      return parts.reduce(
        (accum, current) =>
          accum.concat(orderBy(current, "lastActionAt", ["desc"])),
        []
      );
      // return _.orderBy(parts[0], "lastActionAt", ["desc"]).concat(parts[1])
    },
    // Filtered by search field
    filteredEmployees() {
      if (!this.search || !this.search.length)
        return this.employeesOrderedByAction;
      const regex = new RegExp(`^${this.search}`, "i");
      return this.employeesOrderedByAction.filter(
        (emp) =>
          (emp.fullName && emp.fullName.match(regex)) ||
          (emp.lastName && emp.lastName.match(regex)) ||
          (emp.email && emp.email.match(regex)) ||
          (emp.drsEmployeeCode && emp.drsEmployeeCode.match(regex))
      );
    },
    hasValue() {
      return !!this.value;
    },
    isLoading() {
      return this.$apollo.queries.employee.loading || this.loading;
    },
    avatarColor() {
      return this.hasValue ? "primary" : "grey";
    },
    showAvatar() {
      return this.hasValue || this.isLoading;
    },
    selectedEmployeeName() {
      return this.hasValue ? iget(this.employee, "fullName") : "";
    },
    showFilter() {
      return !this.hideSearch && this.employeesOrderedByAction.length > 5;
    },
  },
  watch: {
    async menu(v) {
      if (!v) {
        this.search = undefined;
      }
    },
  },
  methods: {
    name(employee) {
      return iget(employee, "fullName") || "";
    },
    avatar(employee) {
      return iget(employee, "avatarUrl") || "";
    },
    initial(employee) {
      return (
        (this.name(employee) || "")
          .split(" ")
          .map((e) => e.charAt(0))
          .filter((e) => /[A-Z0-9]/.test(e))
          .slice(0, 2)
          .join("")
          .toUpperCase() || ""
      );
    },
    handleAvatarClick(e) {
      if (!this.employeeAvatarDisabled) {
        this.$celebrate(5, "٭", "gold", e.target);
        this.$emit("complete");
      }
    },
    handleSelect(employee) {
      this.menu = false;
      this.$emit("input", employee);
    },
    onMenuOpen() {
      this.$nextTick(this.$refs.search.focus);
    },
    onClear() {
      return this.$emit("clear");
    },
  },
};
</script>
<style>
.v-list__tile__action.no-min-width {
  min-width: 0;
}
.v-list__tile__avatar.no-min-width {
  min-width: 0;
}
.v-list-item__action:last-of-type:not(:only-child) {
  margin-left: 0px;
}
.employee-select-list {
  max-height: 60vh;
  overflow-y: scroll;
}
.gradient-overlay {
  background-image: linear-gradient(to top, #f00, #00f);
  z-index: 9999999999;
}
</style>
