import get from "lodash/get";

import { format, parseISO } from "date-fns";

export default {
  inheritAttrs: false,
  props: {
    shift: {
      type: Object,
      default: null,
    },
  },
  computed: {
    employee() {
      return get(this.shift, "employee") || { fullName: "Open Shift" };
    },
    avatar() {
      return get(this.employee, "safeAvatarUrl");
    },
    name() {
      return get(this.employee, "fullName");
    },
    notes() {
      return get(this.shift, "notes");
    },
    start() {
      return (
        get(this.shift, "actualStartAt") || get(this.shift, "scheduledStartAt")
      );
    },
    end() {
      return (
        get(this.shift, "actualEndAt") || get(this.shift, "scheduledEndAt")
      );
    },
    timeRange() {
      if (!this.start || !this.end) return "";
      return `${this.start && format(parseISO(this.start), "h:mm aaa")} - ${
        this.end && format(parseISO(this.end), "h:mm aaa")
      }`;
    },
  },
};
