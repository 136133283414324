<template>
  <div v-show="showCompleted" data-testid="completedTodoList">
    <template v-if="visible">
      <CompletedTodo
        v-for="todo in todos"
        :key="todo.id"
        :todo="todo"
        @edit="onEdit"
        @delete="deleteTodo"
      />
      <v-row class="justify-left ml-6 mt-2">
        <v-btn
          text
          color="primary"
          style="text-transform: none"
          small
          @click="visible = false"
          >Hide completed</v-btn
        >
      </v-row>
    </template>
    <v-row v-else class="justify-left ml-6 mt-2">
      <v-btn
        text
        color="primary"
        style="text-transform: none"
        data-testid="completedTodoListShowBtn"
        small
        @click="visible = true"
      >
        Show {{ todos.length }} completed
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import CompletedTodo from "./CompletedTodo";

export default {
  name: "CompletedTodoList",
  components: { CompletedTodo },
  props: {
    todos: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    visible: false,
  }),
  computed: {
    showCompleted() {
      return this.todos.length > 0;
    },
  },
  methods: {
    onEdit(task) {
      return this.$emit("edit", task);
    },
    deleteTodo(todo) {
      return this.$emit("delete", todo);
    },
  },
};
</script>
