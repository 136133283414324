<template>
  <v-list>
    <v-list-item v-for="i in 5" :key="i" mx-1>
      <v-list-item-avatar>
        <v-skeleton-loader type="avatar" />
      </v-list-item-avatar>
      <v-list-item-content style="margin-left: -15px">
        <v-skeleton-loader type="list-item-two-line" />
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "SkeletonScheduleShiftList",
};
</script>

<style></style>
