<script>
import {
  hasRouteTab,
  hasRouteDay,
  hasRouteStore,
  hasChildDialog,
} from "@/mixins/routerParams";

import { parseISO, format } from "date-fns";

import KpisMobile from "./kpis/KpisMobile";
import TasksMobile from "./tasks/TasksMobile";
import NotesMobile from "./notes/NotesMobile";
import ShiftsMobile from "./shifts/ShiftsMobile";

import { KPI_UPDATED } from "./kpis/subscriptions.gql";
import { Kpi, Comps, Bins } from "./kpis/queries.gql";
import { handleKpiUpdated } from "./kpis/subscription_events";

import MobileFab from "./MobileFab";

export default {
  name: "DayViewMobile",
  // destructured lazy-imports to only bundle mobile
  components: {
    KpisMobile,
    NotesMobile,
    TasksMobile,
    ShiftsMobile,
    MobileFab,
  },
  mixins: [hasRouteTab, hasRouteDay, hasRouteStore, hasChildDialog],
  data() {
    return {
      kpi: {},
      comps: {},
      bins: {},
    };
  },
  computed: {
    tabComponent() {
      // like a switch statement
      return {
        kpis: "KpisMobile",
        tasks: "TasksMobile",
        notes: "NotesMobile",
        shifts: "ShiftsMobile",
      }[this.tab];
    },
    fabTo() {
      return {
        kpis: this.dialogRoute({ path: "" }),
        tasks: this.dialogRoute({ name: "newTaskDialog" }),
        notes: this.dialogRoute({ name: "newNoteDialog" }),
        shifts: this.dialogRoute({ name: "newShiftDialog" }),
      }[this.tab];
    },
    variables() {
      return {
        storeId: this.storeId,
        businessDate: this.day,
      };
    },
    // TODO: comps should be handled server-side
    // daybook has a day and a store, that's it.
    compVariables() {
      var oneYearAgoToday = parseISO(this.day).setDate(
        parseISO(this.day).getDate() - 52 * 7
      );
      return {
        storeId: this.storeId,
        businessDate: format(oneYearAgoToday, "yyyy-MM-dd"),
      };
    },
    shouldSkipQueries() {
      return !this.storeId;
    },
  },
  mounted() {
    this.defaultToToday();
  },
  apollo: {
    kpi: {
      query: Kpi,
      subscribeToMore: [
        {
          document: KPI_UPDATED,
          update_query(
            previousResult = { kpis: [] },
            {
              subscriptionData: {
                data: { kpiUpdated },
              },
            }
          ) {
            return handleKpiUpdated(previousResult, kpiUpdated);
          },
        },
      ],
      variables() {
        return this.variables;
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        return data.kpi;
      },
      skip() {
        return this.shouldSkipQueries;
      },
    },
    comps: {
      query: Comps,
      variables() {
        return this.compVariables;
      },
      fetchPolicy: "cache-first",
      update(data) {
        return data.kpi;
      },
      skip() {
        return this.shouldSkipQueries;
      },
    },
    bins: {
      query: Bins,
      variables() {
        return this.variables;
      },
      fetchPolicy: "cache-first",
      update(data) {
        return data.kpi;
      },
      skip() {
        return this.shouldSkipQueries;
      },
    },
  },
};
</script>

<template>
  <keep-alive :key="day">
    <component
      :is="tabComponent"
      :day="day"
      :kpi="kpi"
      :comps="comps"
      :bins="bins"
    >
      <MobileFab :to="fabTo" />
    </component>
  </keep-alive>
</template>
