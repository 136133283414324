<template>
  <!-- <v-hover #default="{ hover }"> -->
  <!-- TODO: handle manager content -->
  <v-list-item
    v-if="shouldShow"
    :id="taskId"
    :ripple="false"
    :class="`pa-0 py-1`"
    style="backface-visibility: hidden"
    data-testid="todoListItem"
    aria-checked="true"
  >
    <Whodunnit
      :employees="[]"
      :employee="todo.employee"
      :completed="true"
      :completed-at="new Date(todo.completedAt)"
      :active-employee="{ id: activeEmployeeId }"
      @assign="assignTask"
      @complete="completeTask"
      @clear="clearTask"
    />
    <v-list-item-content class="ml-2">
      <v-tooltip
        :attach="`#${taskId}`"
        content-class="task-list__item__tooltip"
        :disabled="tooltipDisabled"
        open-delay="300"
      >
        <template #activator="{ on }">
          <v-list-item-title
            ref="taskName"
            class="task-list__item__name complete text-body-2 text--secondary multiline-ellipsis"
            data-test-ref="task-list__item__name"
            v-on="on"
            v-text="todo.name"
          />
        </template>
        <span v-text="todo.name" />
      </v-tooltip>
    </v-list-item-content>
    <v-list-item-action data-test-ref="task-list__item__due-time">
      <v-tooltip top>
        <template #activator="{ on }">
          <p :class="`${dynamicTimeColorClass} mb-0 overline`" v-on="on">
            {{ completedAtDisplay }}
          </p>
        </template>
        <span v-text="completedTooltipText" />
      </v-tooltip>
    </v-list-item-action>
    <!-- manager only key icon -->
    <!-- TODO: handle manager content -->
    <v-list-item-action v-if="todo.isManagerOnly">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-icon
            size="20"
            color="warning"
            v-on="on"
            v-text="`$vuetify.icons.key`"
          />
        </template>
        <span>Manager Only Task</span>
      </v-tooltip>
    </v-list-item-action>
    <!-- Overflow menu -->
    <v-list-item-action v-if="todo && localLoading">
      <v-progress-circular indeterminate />
    </v-list-item-action>
    <v-list-item-action v-else-if="showEditMenu && false">
      <v-menu :disabled="dateIsPast">
        <template #activator="{ on }">
          <v-icon
            size="20"
            :color="dateIsPast ? 'grey lighten-2' : 'grey'"
            :disabled="dateIsPast"
            v-on="on"
            v-text="`$vuetify.icons.more`"
          />
        </template>
        <v-list>
          <v-list-item @click="$emit('edit', todo)">
            <v-list-item-content v-text="`Edit`" />
          </v-list-item>
          <v-list-item
            v-if="activeEmployeeCan.deleteTask"
            @click="$emit('delete', todo)"
          >
            <v-list-item-content v-text="`Delete`" />
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-action>
  </v-list-item>
  <!-- </v-hover> -->
</template>

<script>
import Whodunnit from "@/components/daybook/Whodunnit";

import { isBefore, format, startOfDay, parseISO } from "date-fns";
import {
  hasActiveEmployeeIdAccess,
  injectActiveEmployee,
  injectEmployees,
} from "@/mixins/employee";
import { mapGetters } from "vuex";
import {
  taskProps,
  taskComputedProperties,
  taskMethods,
  taskMixins,
  taskData,
  taskHooks,
} from "@/mixins/task";
import { hasRouteDay } from "@/mixins/routerParams";
export default {
  name: "CompletedTodo",
  components: {
    Whodunnit,
  },
  mixins: [
    hasActiveEmployeeIdAccess,
    injectEmployees,
    taskProps,
    taskComputedProperties,
    taskMethods,
    taskMixins,
    taskData,
    taskHooks,
    injectActiveEmployee,
    hasRouteDay,
  ],
  props: {
    todo: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isPastDue: false,
    completed: true,
  }),
  computed: {
    ...mapGetters("bizdate", ["getBizDate"]),
    pastDue() {
      return (
        (this.todo.dueAt &&
          this.todo.completedAt &&
          isBefore(
            parseISO(this.todo.dueAt),
            parseISO(this.todo.completedAt)
          )) ||
        false
      );
    },
    dynamicTimeColorClass() {
      if (this.pastDue) return "red--text";
      if (this.completed) return "success--text";
      return null;
    },
    completedAtDisplay() {
      return `${format(parseISO(this.todo.completedAt), "h:mm aaa")}`;
    },
    completedTooltipText() {
      if (this.todo.dueAt) {
        return `Due ${format(
          parseISO(this.todo.dueAt),
          "MMM d @ h:mm aaa"
        ).replace("@", "at")}`;
      }
      return "Completed on time";
    },
    dateIsPast() {
      return this.dayDate < startOfDay(new Date());
    },
  },
};
</script>

<style>
.complete {
  text-decoration: line-through;
}
.task-list__item__tooltip {
  top: calc(-1 * fit-content(2em)) !important;
}
</style>
