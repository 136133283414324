<template>
  <div class="schedule">
    <ScheduleShiftList @select="selectShift" />
    <ShiftDialogEdit v-model="dialog" :shift="selectedShift" />
  </div>
</template>

<script>
import ScheduleShiftList from "@/components/daybook/ScheduleShiftList";
import ShiftDialogEdit from "@/components/daybook/ShiftDialogEdit";
import { injectActiveEmployee } from "@/mixins/employee";

export default {
  components: {
    ScheduleShiftList,
    ShiftDialogEdit,
  },
  mixins: [injectActiveEmployee],
  data: () => ({
    dialog: false,
    selectedShift: {},
  }),
  watch: {
    dialog(v, o) {
      if (!v) {
        this.selectedShift = {};
      }
    },
  },
  methods: {
    selectShift(shift) {
      if (!this.activeEmployeeCan.editShift) return false;

      this.selectedShift = shift;
      this.dialog = true;
    },
  },
};
</script>
