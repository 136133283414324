var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [(_vm.shouldShow && _vm.isStarted)?_c('v-list-item',{class:`rai-task__incomplete pa-0 py-1`,attrs:{"id":_vm.taskId,"elevation":"12","ripple":false,"data-testid":"todoListItem"}},[_c('whodunnit',{attrs:{"employees":_vm.employees,"employee":_vm.todo.employee,"completed":_vm.completed,"completed-at":_vm.completedAt,"active-employee":{ id: _vm.activeEmployeeId }},on:{"assign":function($event){return _vm.handleTaskAssignment($event, _vm.todo)},"complete":_vm.completeTask,"clear":_vm.clearTask,"clear-employee":_vm.clearEmployee}}),_c('v-tooltip',{attrs:{"attach":`#${_vm.taskId}`,"content-class":"task-list__item__tooltip","disabled":_vm.tooltipDisabled,"open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-list-item-content',_vm._g({staticClass:"ml-2"},on),[_c('v-list-item-title',{ref:"taskName",class:{
              'body-2': true,
              'mt-1': !!_vm.dueAt,
              'pr-1': hover,
              'pr-8': !hover,
              'red--text text--darken-1': _vm.pastDue,
              'text--primary': !_vm.pastDue,
              'multiline-ellipsis': true,
            },attrs:{"data-test-ref":"task-list__item__name"},domProps:{"textContent":_vm._s(_vm.todo.name)}}),(!!_vm.dueAt)?_c('v-list-item-subtitle',{class:{
              'red--text text--lighten-2': _vm.pastDue,
              'text--disabled': !_vm.pastDue,
              'caption mb-n3': true,
            }},[_vm._v(_vm._s(_vm.dueLabel)+" ")]):_vm._e()],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.todo.name)}})]),(_vm.dueAt && !_vm.shouldDisplayCounter)?_c('v-list-item-action',{attrs:{"data-test-ref":"task-list__item__due-time"}}):(_vm.isPastDue)?_c('v-list-item-action-text',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({class:_vm.dynamicTimeColorClass,domProps:{"textContent":_vm._s(`Past due`)}},on))]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.dueAtDisplay)}})])],1):_vm._e(),(_vm.todo.isManagerOnly)?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"size":"20","color":"warning"},domProps:{"textContent":_vm._s(`$vuetify.icons.key`)}},on))]}}],null,true)},[_c('span',[_vm._v("Manager Only Task")])])],1):_vm._e(),(_vm.todo && _vm.localLoading)?_c('v-list-item-action',[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):(_vm.showEditMenu)?_c('v-list-item-action',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"pa-0 ma-0"},[_c('v-menu',{attrs:{"disabled":_vm.dateIsPast},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"size":"20","color":_vm.dateIsPast ? 'grey lighten-2' : 'grey',"disabled":_vm.dateIsPast},domProps:{"textContent":_vm._s(`$vuetify.icons.more`)}},on))]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('edit', _vm.todo)}}},[_c('v-list-item-content',{domProps:{"textContent":_vm._s(`Edit`)}})],1),(_vm.activeEmployeeCan.deleteTask)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('delete', _vm.todo)}}},[_c('v-list-item-content',{domProps:{"textContent":_vm._s(`Delete`)}})],1):_vm._e()],1)],1)],1):_vm._e()],1):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }