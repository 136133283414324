import { get, set } from "lodash";

// Returns an object of format `{ input: { ... } }`, expected
// to be used as an `UpdateShiftInputObject`
//
// Given `oldValue` and `newValue`, dynamically builds
// an `UpdateShiftInputObject` with only the necessariy fields
export const buildUpdateShiftInputObject = (
  oldValue,
  newValue,
  inputObject = { input: {} }
) => {
  return [
    "businessDate",
    "scheduledStartAt",
    "scheduledEndAt",
    "employee.id",
    "events",
    "notes",
  ].reduce(
    (acc, prop) => determineBuilderFn(prop)(oldValue, newValue, acc),
    inputObject
  );
};

// Returns a fn for the reduce call in `buildUpdateShiftInputObject`
const determineBuilderFn = (prop) => {
  switch (prop) {
    case "events":
      return eventsBuilder;
      break;
    case "employee.id":
      return employeeBuilder;
      break;
    default:
      return propertyBuilder(prop);
  }
};

// Returns a fn that will update `accumulator` with the value at `newValue[path]`
// if `oldValue[path]` && `newValue[path]` differ
//
// Returned fn returns an accumulator of format `{ input: {...} }`
const propertyBuilder = (path) => (oldValue, newValue, accumulator) => {
  if (get(oldValue, path) !== get(newValue, path))
    return set(accumulator, `input.${path}`, get(newValue, path));

  return accumulator;
};

const employeeBuilder = (oldValue, newValue, accumulator) => {
  if (get(oldValue, "employee.id") !== get(newValue, "employee.id"))
    return set(accumulator, `input.employeeId`, get(newValue, "employee.id"));

  return accumulator;
};

// Returns an object of format `{ id: oldValue.id, field1: updatedValue1, ... }`
const buildUpdateShiftEventInputObject = (oldValue, newValue) => {
  return ["title", "startAt", "endAt"].reduce(
    (acc, field) => {
      if (oldValue[field] !== newValue[field]) {
        set(acc, field, newValue[field]);
        return acc;
      }
      return acc;
    },
    { id: oldValue.id }
  );
};

// Returns an accumulator of format `{ input: { createEvents: [...], updateEvents: [...] } }`
const eventsBuilder = (oldValue, newValue, accumulator) => {
  // iterate newValue.events
  // for each, if iteratedEvent has no ID, add it to the accumulator
  return newValue.events.reduce((acc, iteratedEvent) => {
    if (!iteratedEvent.id) {
      acc.input.createEvents = []
        .concat(acc.input.createEvents || [])
        .concat([iteratedEvent]);
      return acc;
    }

    // if it has an ID and a field that has changed, add it to the acculator
    const existingEvent = oldValue.events.find(
      (oldEvent) =>
        oldEvent.id === iteratedEvent.id &&
        JSON.stringify(oldEvent) !== JSON.stringify(iteratedEvent)
    );
    // If updating an event, only add the fields that have changed.
    // Example:
    //   { id: 1, startAt: "a", endAt: "b" }  << original
    //   { id: 1, startAt: "a", endAt: "z" }  << new value
    //   { id: 1, endAt: "z" }                << return value
    if (existingEvent) {
      const eventUpdate = buildUpdateShiftEventInputObject(
        existingEvent,
        iteratedEvent
      );
      acc.input.updateEvents = []
        .concat(acc.input.updateEvents || [])
        .concat([eventUpdate]);
      return acc;
    }

    // otherwise, return the acculator 'as is'
    return acc;
  }, accumulator);
};
