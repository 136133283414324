<template>
  <!-- TODO: handle manager content -->
  <v-list-item v-if="shouldShow" :id="taskId" :ripple="false" :class="`pa-0`">
    <Whodunnit
      :employees="employees"
      :employee="employee"
      :completed="true"
      :completed-at="new Date(completedAt)"
      :active-employee="{ id: activeEmployeeId }"
      @assign="assignTask"
      @complete="completeTask"
      @clear="clearTask"
    />
    <v-list-item-content class="ml-2">
      <v-tooltip
        :attach="`#${taskId}`"
        content-class="task-list__item__tooltip"
        :disabled="tooltipDisabled"
        open-delay="300"
      >
        <template #activator="{ on }">
          <v-list-item-title
            ref="taskName"
            class="task-list__item__name complete text--secondary text-body-2 multiline-ellipsis"
            data-test-ref="task-list__item__name"
            v-on="on"
            v-text="task.name"
          />
        </template>
        <span class="text-body-2" v-text="task.name" />
      </v-tooltip>
    </v-list-item-content>
    <v-list-item-action data-test-ref="task-list__item__due-time">
      <v-tooltip top :disabled="!completedTooltipText">
        <template #activator="{ on }">
          <p :class="`overline ${dynamicTimeColorClass} mb-0`" v-on="on">
            {{ completedAtDisplay }}
          </p>
        </template>
        <span v-text="completedTooltipText" />
      </v-tooltip>
    </v-list-item-action>
    <!-- TODO: handle manager content -->
    <!-- manager only key icon -->
    <v-list-item-action v-if="task.isManagerOnly">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-icon
            size="20"
            color="warning"
            v-on="on"
            v-text="`$vuetify.icons.key`"
          />
        </template>
        <span>Manager Only Task</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import Whodunnit from "@/components/daybook/Whodunnit";

import { isBefore, format, parseISO, isValid } from "date-fns";
import {
  hasActiveEmployeeIdAccess,
  injectActiveEmployee,
} from "@/mixins/employee";
import { hasRouteDay } from "@/mixins/routerParams";
import { mapGetters } from "vuex";
import {
  taskProps,
  taskComputedProperties,
  taskMethods,
  taskMixins,
  taskData,
  taskHooks,
} from "@/mixins/task";
export default {
  name: "TaskListItemComplete",
  components: {
    Whodunnit,
  },
  mixins: [
    hasActiveEmployeeIdAccess,
    taskProps,
    taskComputedProperties,
    taskMethods,
    taskMixins,
    taskData,
    taskHooks,
    injectActiveEmployee,
    hasRouteDay,
  ],
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    todo: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isPastDue: false,
    completed: true,
  }),
  computed: {
    ...mapGetters("bizdate", ["getBizDate"]),
    dueAt() {
      if (!this.task.dueTime) return null;
      var v = this.task.dueTime.split(":");

      var hour = v[0];
      var min = v[1];

      var d = new Date(this.dayDate);

      d.setHours(hour, min);

      return d;
    },
    pastDue() {
      return (
        (this.dueAt &&
          this.task.completed &&
          isBefore(this.dueAt, parseISO(this.task.completed.completedAt))) ||
        false
      );
    },
    dynamicTimeColorClass() {
      if (this.pastDue) return "red--text";
      if (!!this.task.completed) return "success--text";
      return null;
    },
    completedAtDisplay() {
      return `${format(parseISO(this.task.completed.completedAt), "h:mm aaa")}`;
    },
    completedTooltipText() {
      return isValid(this.dueAt)
        ? `Due ${format(this.dueAt, "@ h:mm aaa").replace("@", "at")}`
        : "";
    },
  },
};
</script>

<style>
.complete {
  text-decoration: line-through;
}
.task-list__item__tooltip {
  top: calc(-1 * fit-content(2em)) !important;
}
</style>
