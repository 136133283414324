import gql from "graphql-tag";

const TODO_FRAGMENT = gql`
  fragment TodoBasicInfo on Todo {
    id
    name
    completed
    completedAt
    businessDate
    isManagerOnly
    dueAt
    employee {
      id
      fullName
      avatarUrl
      safeAvatarUrl
    }
  }
`;

export const ACTIVE_TASK_LISTS = gql`
  query ACTIVE_TODOS($storeId: ID!, $businessDate: ISO8601Date!) {
    todos: todos(storeId: $storeId, businessDate: $businessDate) {
      ...TodoBasicInfo
    }
  }
  ${TODO_FRAGMENT}
`;

export const TODO_CREATED_SUB = gql`
  subscription TODO_CREATED($businessDate: ISO8601Date!) {
    todoCreated(businessDate: $businessDate) {
      ...TodoBasicInfo
    }
  }
  ${TODO_FRAGMENT}
`;

export const TODO_UPDATED_SUB = gql`
  subscription TODO_UPDATED($businessDate: ISO8601Date!) {
    todoUpdated(businessDate: $businessDate) {
      ...TodoBasicInfo
    }
  }
  ${TODO_FRAGMENT}
`;

export const TASK_CHECK_UPDATED_SUB = gql`
  subscription TASK_CHECK_UPDATED($businessDate: ISO8601Date!) {
    taskCheckUpdated(businessDate: $businessDate) {
      id
      completed(businessDate: $businessDate) {
        id
        completedAt
        employee {
          id
          fullName
          avatarUrl
          safeAvatarUrl
        }
      }
    }
  }
`;
