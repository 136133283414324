import gql from "graphql-tag";

const NOTE_FRAGMENT = gql`
  fragment EmployeeBasicInfo on Employee {
    id
    ...EmployeeName
    ...EmployeeContactInfo
    ...EmployeeMeta
    avatarUrl
    safeAvatarUrl
    drsEmployeeCode
  }

  fragment EmployeeName on Employee {
    fullName
    firstName
    lastName
  }

  fragment EmployeeContactInfo on Employee {
    phoneNumber
    email
    emergencyName
    emergencyPhone
  }

  fragment EmployeeMeta on Employee {
    isManager
    isActive
    lastActionAt
    hasPin
    clockedIn
  }

  fragment NoteBasicInfo on Note {
    id
    body
    businessDate
    endDate
    isManagerOnly
    updatedAt
    createdAt
    employee {
      ...EmployeeBasicInfo
    }
    likes {
      id
      createdAt
      employee {
        id
        fullName
      }
    }
    commentsCount
    lastComment {
      id
      createdAt
      body
      employee {
        id
      }
      likes {
        id
        createdAt
        employee {
          id
          fullName
        }
      }
    }
  }
`;

export const DAY_NOTES = gql`
  query DAY_NOTES($storeId: ID!, $businessDate: ISO8601Date) {
    notes(storeId: $storeId, businessDate: $businessDate) {
      ...NoteBasicInfo
    }
  }
  ${NOTE_FRAGMENT}
`;

export const NOTE_CREATED_SUB = gql`
  subscription NOTE_CREATED_SUB {
    noteCreated {
      ...NoteBasicInfo
    }
  }
  ${NOTE_FRAGMENT}
`;

export const NOTE_UPDATED_SUB = gql`
  subscription NOTE_UPDATED_SUB {
    noteUpdated {
      ...NoteBasicInfo
    }
  }
  ${NOTE_FRAGMENT}
`;
