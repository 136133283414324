<template>
  <VxQuery
    :query="$options.SHIFTS_TODAY"
    :variables="{
      storeId: storeId,
      businessDate: day,
    }"
    fetch-policy="cache-and-network"
  >
    <template #loading>
      <SkeletonScheduleShiftList />
    </template>
    <template #data="{ data: { shifts } }">
      <v-list
        v-if="shifts.length"
        class="schedule-list"
        data-testid="shiftList"
        two-line
      >
        <template v-for="shift in orderShifts(shifts)">
          <ScheduleShiftListItem
            :key="shift.id"
            data-testid="shiftListItem"
            :shift="shift"
          />
        </template>
      </v-list>
    </template>
    <template #emptyContent>
      <v-icon large>$noShifts</v-icon>
    </template>
  </VxQuery>
</template>

<script>
import VxQuery from "@/components/vx/VxQuery";
import ScheduleShiftListItem from "@/components/daybook/DayBookView/ScheduleListItem";
import SkeletonScheduleShiftList from "@/components/daybook/SkeletonScheduleShiftList";
import { SHIFTS_TODAY } from "@/graphql/shift/queries.gql";
import { injectActiveEmployee } from "@/mixins/employee";
import { hasRouteDay } from "@/mixins/routerParams";
import { mapGetters } from "vuex";

import { startOfToday } from "date-fns";

export default {
  name: "ScheduleShiftList",
  components: {
    ScheduleShiftListItem,
    SkeletonScheduleShiftList,
    VxQuery,
  },
  SHIFTS_TODAY,
  mixins: [injectActiveEmployee, hasRouteDay],
  data: () => ({
    shifts: [],
  }),
  computed: {
    storeId() {
      return this.$route.params.storeId;
    },
  },
  methods: {
    clockedIn(shift) {
      const actualStart = shift.actualStartAt;
      const actualEnd = shift.actualEndAt;
      return !!(actualStart && !actualEnd);
    },
    orderShifts(shifts) {
      const clockedIn = [];
      const comingSoon = [];
      const goneHome = [];
      for (let i = 0; i < shifts.length; i++) {
        if (this.clockedIn(shifts[i])) {
          clockedIn.push(shifts[i]);
        } else if (
          shifts[i].actualEndAt !== null &&
          startOfToday() > shifts[i].actualEndAt
        ) {
          goneHome.push(shifts[i]);
        } else {
          comingSoon.push(shifts[i]);
        }
      }
      return [...clockedIn, ...comingSoon, ...goneHome];
    },
  },
};
</script>
