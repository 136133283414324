<script>
import Notes from "@/components/daybook/Notes";

export default {
  name: "NotesMobile",
  components: {
    Notes,
  },
};
</script>
<template>
  <v-container fluid>
    <Notes />
    <slot />
  </v-container>
</template>
