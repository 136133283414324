<template>
  <div class="todos" data-testid="todoListCard">
    <div v-if="$apollo.queries.todos.loading" class="fill-height">
      <v-card flat class="pa-0 mb-3">
        <v-card-title
          data-test-ref="todo-list__name"
          class="pa-0"
          @click="toggleCollapse"
        >
          <v-subheader>
            <v-icon size="17" class="mr-1" v-text="collapseIcon" />Today's Tasks
          </v-subheader>
          <v-spacer />
          <span class="text-caption rai-data text--secondary pr-3">0%</span>
        </v-card-title>
        <v-divider />

        <v-card-text class="py-0">
          <v-slide-y-transition>
            <v-list transition="scale-transition">
              <v-skeleton-loader
                v-for="i in 3"
                :key="i"
                type="list-item-avatar"
                class="py-0 px-2"
              />
            </v-list>
          </v-slide-y-transition>
        </v-card-text>
        <v-card-actions v-if="!collapsed">
          <AddTodoField v-model="newTodo" class="mt-4" disabled />
        </v-card-actions>
      </v-card>
    </div>
    <div v-else class="fill-height">
      <v-card flat class="pa-0 mb-3">
        <v-card-title
          data-test-ref="todo-list__name"
          class="pa-0"
          @click="toggleCollapse"
        >
          <v-subheader>
            <v-icon size="17" class="mr-1" v-text="collapseIcon" />Today's Tasks
          </v-subheader>
          <v-spacer />
          <span class="text-caption rai-data text--secondary pr-3">
            {{ statsDisplay }}
          </span>
        </v-card-title>
        <v-divider />

        <v-card-text class="py-0 pb-1">
          <v-slide-y-transition>
            <v-list v-if="!collapsed" class="todo-list" data-testid="todoList">
              <IncompleteTodos
                :todos="incompleteTodos(todos)"
                @edit="editTask"
                @delete="deleteTodo"
              />
              <CompletedTodos
                :todos="completedTodos(todos)"
                @edit="editTask"
                @delete="deleteTodo"
              />
            </v-list>
          </v-slide-y-transition>
        </v-card-text>
        <!-- do not show actions for previous days -->
        <v-card-actions v-if="!collapsed && !dayIsPast">
          <EmployeeSelector
            v-if="newTodo.length > 0"
            :employees="employees"
            :assign-mode="true"
            :hide-search="false"
            class="mt-n4"
            @input="addTodo"
          />
          <AddTodoField
            v-model="newTodo"
            :class="`mt-4`"
            :loading="newTodoIsLoading"
            @submit="addTodo"
          />
        </v-card-actions>
      </v-card>
    </div>

    <TaskDialogEdit v-model="dialog" :task="selectedTask" />
  </div>
</template>

<script>
import AddTodoField from "./TaskListAddTodoField";
import CompletedTodos from "./CompletedTodos";
import IncompleteTodos from "./IncompleteTodos";
import TaskDialogEdit from "./TaskDialogEdit";

import { injectActiveEmployee } from "@/mixins/employee";
import { hasGlobalLoaderAccess, hasSnackbarAccess } from "@/mixins/ui";
import { hasRouteDay, hasRouteStore } from "@/mixins/routerParams";

import {
  ACTIVE_TASK_LISTS,
  TODO_UPDATED_SUB,
  TODO_CREATED_SUB,
} from "@/graphql/task/queries.js";
import { CREATE_TODO, DELETE_TODO } from "@/graphql/todo/mutations.gql";

import { format, parseISO } from "date-fns";
import { mapMutations } from "vuex";
import findIndex from "lodash/findIndex";

export default {
  name: "TodoListCard",
  components: {
    AddTodoField,
    TaskDialogEdit,
    CompletedTodos,
    IncompleteTodos,
    EmployeeSelector: () => import("../EmployeeSelect"),
  },

  mixins: [
    injectActiveEmployee,
    hasGlobalLoaderAccess,
    hasSnackbarAccess,
    hasRouteDay,
    hasRouteStore,
  ],

  data: () => ({
    newTodo: "",
    newTodoIsLoading: false,
    lastActiveCheck: null,
    evalLists: true,
    selectedTask: null,
    collapsed: false,
    dialog: false,
    todos: [],
  }),

  apollo: {
    todos: {
      query: ACTIVE_TASK_LISTS,
      variables() {
        return this.variables;
      },
      update({ todos }) {
        return this.getActiveTodos(todos);
      },

      subscribeToMore: [
        {
          document: TODO_CREATED_SUB,
          updateQuery(previousResult, context) {
            return this.onTodoCreatedSub(previousResult, context);
          },
          variables() {
            return this.variables;
          },
          skip() {
            return !this.dayIsToday;
          },
        },
        {
          document: TODO_UPDATED_SUB,
          variables() {
            return this.variables;
          },
          skip() {
            return !this.dayIsToday;
          },
        },
      ],
    },
  },

  computed: {
    variables() {
      return {
        storeId: this.storeId,
        businessDate: this.day,
      };
    },
    collapseIcon() {
      return (
        (this.collapsed && `$vuetify.icons.plus`) || `$vuetify.icons.minus`
      );
    },
    statsDisplay() {
      if (this.todos.length === 0) {
        return "--";
      } else {
        return `${this.stats(this.todos)}%`;
      }
    },
  },

  watch: {
    dialog(v, o) {
      if (!v) {
        this.selectedTask = null;
      }
    },
  },

  methods: {
    ...mapMutations("ui", ["loading", "loaded"]),
    ...mapMutations("snackbar", ["showSnackbar"]),
    getActiveTodos(todos) {
      let activeTodos = todos;

      if (!this.activeEmployeeCan.seeManagerContent) {
        activeTodos = todos.filter(({ isManagerOnly }) => !isManagerOnly);
      }

      // Why do this for todos?
      // Todos do not have a day of the week associated with them
      // Show the todo if it was created on or before the current day and was completed on or after the current day
      return activeTodos.filter((todo) =>
        this.isActiveToday(todo, format(parseISO(this.day), "iiii"))
      );
    },

    isActiveToday(item, day) {
      if (item.__typename == "TaskList") {
        var day_dict = {
          Monday: item.monday,
          Tuesday: item.tuesday,
          Wednesday: item.wednesday,
          Thursday: item.thursday,
          Friday: item.friday,
          Saturday: item.saturday,
          Sunday: item.sunday,
        };
        return day_dict[day];
      } else return true;
    },
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
    stats(todos) {
      return Math.round(
        (this.completedTodos(todos).length / this.numTodos(todos)) * 100
      );
    },
    numTodos(todos) {
      return todos.length;
    },
    completedTodos(todos) {
      return todos.filter((todo) => todo.completed);
    },
    incompleteTodos(todos) {
      return todos.filter((todo) => !todo.completed);
    },
    addTodo(employee = {}) {
      //const vm = this;
      this.newTodoIsLoading = true;
      var end = this.dayDate;
      end.setHours(23, 59, 59);
      const variables = {
        input: {
          name: this.newTodo,
          businessDate: this.day,
          dueAt: end,
          employeeId: employee.id,
        },
      };
      this.$apollo
        .mutate({
          mutation: CREATE_TODO,
          variables,
          update: (
            store,
            {
              data: {
                createTodo: { todo },
              },
            }
          ) => {
            const data = store.readQuery({
              query: ACTIVE_TASK_LISTS,
              variables: this.variables,
            });
            data.todos.push(todo);
            store.writeQuery({
              query: ACTIVE_TASK_LISTS,
              variables: this.variables,
              data,
            });
          },
        })
        .catch((error) => {
          // TODO this should be a modal alert, not a snackbar
          this.showSnackbar({
            test: `An error occurred while creating the todo. Error: ${error}`,
          });
        })
        .then(() => {
          this.newTodo = "";
          this.newTodoIsLoading = false;
        });
    },
    editTask(task) {
      this.selectedTask = task;
      this.dialog = true;
    },
    async deleteTodo(todo) {
      if (!this.activeEmployeeCan.deleteTask) return false;

      this.loading(this.deleteLoadingKey(todo));
      const variables = this.variables;

      try {
        await this.$apollo.mutate({
          mutation: DELETE_TODO,
          variables: {
            id: todo.id,
          },
          optimisticResponse: {
            __typename: "DeletePayload",
            deleteTodo: {
              __typename: "DeleteTodo",
              todo: { __typename: "Todo", id: todo.id },
              errors: [],
            },
          },
          // why not just refresh the query using $apollo.queries.todos.refetch() or refresh()?
          update(
            store,
            {
              data: {
                deleteTodo: {
                  todo: { id },
                  errors,
                },
              },
            }
          ) {
            const data = store.readQuery({
              query: ACTIVE_TASK_LISTS,
              variables,
            });
            const todos = data && data.todos;
            if (!todos) return;

            const index = todos.findIndex((t) => t.id === id);
            if (index < 0) return;

            const newTodos = [
              ...todos.slice(0, index),
              ...todos.slice(index + 1),
            ];

            data.todos = newTodos;
            store.writeQuery({
              query: ACTIVE_TASK_LISTS,
              variables,
              data,
            });
          },
        });
        this.showSnackbar({
          text: `Removed todo successfully`,
        });
      } catch (error) {
        this.showSnackbar({
          text: `An error occurred while deleting this todo: ${error}`,
        });
      }
      return this.loaded(this.deleteLoadingKey(todo));
    },
    deleteLoadingKey(task) {
      return `delete-todo-${task.id}`;
    },
    onTodoCreatedSub(previousResult, { subscriptionData }) {
      const todo = subscriptionData.data.todoCreated ?? {};

      if (findIndex(previousResult.todos, { id: todo.id }) > -1)
        return previousResult;

      const newResult = {
        todos: [...previousResult.todos],
      };

      newResult.todos.push(todo);
      return newResult;
    },
  },
};
</script>
