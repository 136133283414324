<script>
import { hasRouteDay } from "@/mixins/routerParams";

import DayKpiText from "../DayKpiText";
import KpisMobileExpanded from "./KpisMobileExpanded";

export default {
  name: "KpisMobile",
  components: {
    DayKpiText,
    KpisMobileExpanded,
  },
  mixins: [hasRouteDay],
  props: {
    kpi: {
      type: Object,
      default: () => ({}),
    },
    comps: {
      type: Object,
      default: () => ({}),
    },
    bins: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      display: true,
      expanded: false,
      hover: false,
    };
  },
  computed: {
    showHover() {
      return this.hover ? this.hover : this.expanded;
    },
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
    formatData(data) {
      if (!data) return "0";
      data = (Math.round(data * 100) / 100).toFixed(2);
      return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    computedColor(actual, goal, type = null) {
      if (!goal) return null;
      if (
        !this.kpi.closed &&
        (type === "sales" ||
          (type === "trade" && this.kpi.buysSorted < 1) ||
          (type === "trans" && this.kpi.transactions < 1))
      ) {
        return actual > goal ? "success--text" : "info--text";
      } else {
        return actual > goal ? "success--text" : "error--text";
      }
    },
    goalDifference(actual, goal) {
      var diff = actual - goal;
      return `${diff > 0 ? "+" : "-"} $${this.formatData(Math.abs(diff))}`;
    },
  },
};
</script>

<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <span class="text-subtitle-1 text--secondary">Sales</span>
        <DayKpiText
          prefix="$"
          :data="kpi.sales"
          :color="computedColor(kpi.sales, kpi.salesGoal, 'sales')"
        />
        <v-row class="align-center">
          <v-col cols="6" class="text-right pa-1">
            <span class="text-overline">Goal:</span>
          </v-col>
          <v-col cols="6" class="pa-1">
            <span class="rai-data text--primary font-weight-medium"
              >${{ formatData(kpi.salesGoal) }}</span
            >
          </v-col>
          <v-col cols="12" class="text-right">
            <span
              v-if="kpi.closed"
              :class="`rai-data ${computedColor(kpi.sales, kpi.salesGoal)}`"
              >{{ goalDifference(kpi.sales, kpi.salesGoal) }}</span
            >
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="6" class="text-right pa-1">
            <span class="text-overline">Comps:</span>
          </v-col>
          <v-col cols="6" class="pa-1">
            <span class="rai-data text--primary font-weight-medium"
              >${{ formatData(comps.sales) }}</span
            >
          </v-col>
        </v-row>
        <v-col>
          <v-progress-linear
            :value="(kpi.sales / kpi.salesGoal) * 100"
            :color="kpi.sales / kpi.salesGoals >= 1 ? 'success' : 'primary'"
          ></v-progress-linear>
        </v-col>
      </v-col>
      <v-col cols="12">
        <span class="text-subtitle-1 text--secondary">Avg Trans</span>
        <DayKpiText
          prefix="$"
          :data="kpi.avgTrans"
          :color="computedColor(kpi.avgTrans, kpi.avgTransGoal, 'trans')"
        />
        <v-row class="align-center">
          <v-col cols="6" class="text-right pa-1">
            <span class="text-overline">Goal:</span>
          </v-col>
          <v-col cols="6" class="pa-1">
            <span class="rai-data text--primary font-weight-medium"
              >${{ formatData(kpi.avgTransGoal) }}</span
            >
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="6" class="text-right pa-1">
            <span class="text-overline">Comps:</span>
          </v-col>
          <v-col cols="6" class="pa-1">
            <span class="rai-data text--primary font-weight-medium"
              >${{ formatData(comps.avgTrans) }}</span
            >
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <span class="text-subtitle-1 text--secondary">Trade %</span>
        <DayKpiText
          suffix="%"
          :data="kpi.tradePct"
          :color="computedColor(kpi.tradePct, kpi.tradePctGoal, 'trade')"
        />
        <v-row class="align-center">
          <v-col cols="6" class="text-right pa-1">
            <span class="text-overline">Goal:</span>
          </v-col>
          <v-col cols="6" class="pa-1">
            <span class="rai-data text--primary font-weight-medium"
              >${{ formatData(kpi.tradePctGoal) }}</span
            >
          </v-col>
        </v-row>
        <v-row class="align-center">
          <v-col cols="6" class="text-right pa-1">
            <span class="text-overline">Comps:</span>
          </v-col>
          <v-col cols="6" class="pa-1">
            <span class="rai-data text--primary font-weight-medium"
              >${{ formatData(comps.tradePct) }}</span
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <KpisMobileExpanded :bins="bins" :kpi="kpi" :comps="comps" />
  </v-container>
</template>
