var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.shouldShow && _vm.isStarted)?_c('v-list-item',{class:`rai-task__incomplete pa-0 ${
    _vm.employeeChange ? 'employee-change' : ''
  }`,attrs:{"id":_vm.taskId,"ripple":false,"data-test-ref":_vm.todo ? `task-list__item--todo` : `task-list__item`}},[_c('Whodunnit',{attrs:{"employees":_vm.employees,"employee":_vm.employee,"completed":_vm.completed,"completed-at":_vm.completedAt,"active-employee":{ id: _vm.activeEmployeeId }},on:{"assign":_vm.assignEmployee,"complete":_vm.completeTask,"clear":_vm.clearTask,"clear-employee":_vm.clearEmployee}}),_c('v-tooltip',{attrs:{"attach":`#${_vm.taskId}`,"content-class":"task-list__item__tooltip","disabled":_vm.tooltipDisabled,"open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-list-item-content',_vm._g({staticClass:"ml-2"},on),[_c('v-list-item-title',{ref:"taskName",class:`body-2 ${!!_vm.dueAt ? 'mt-1' : ''} ${
            _vm.pastDue ? 'red--text text--darken-1' : 'text--primary'
          } multiline-ellipsis`,attrs:{"data-test-ref":"task-list__item__name"},domProps:{"textContent":_vm._s(_vm.task.name)}}),(!!_vm.dueAt)?_c('v-list-item-subtitle',{class:`${
            _vm.pastDue ? 'red--text text--lighten-2' : 'text--disabled'
          } caption mb-n3`},[_vm._v(_vm._s(_vm.formattedDueAt))]):_vm._e()],1)]}}],null,false,1010779378)},[_c('span',{domProps:{"textContent":_vm._s(_vm.task.name)}})]),(_vm.shouldDisplayCounter && !_vm.pastDue)?_c('v-list-item-action-text',[_c('span',{staticClass:"rai-data",domProps:{"textContent":_vm._s(_vm.dueCounter)}})]):_vm._e(),(_vm.task.isManagerOnly)?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({attrs:{"size":"20","color":"warning"},domProps:{"textContent":_vm._s(`$vuetify.icons.key`)}},on))]}}],null,false,4128914936)},[_c('span',[_vm._v("Manager Only Task")])])],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }