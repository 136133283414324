<template>
  <v-dialog
    v-bind="$attrs"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    class="rai-form-dialog"
    v-on="$listeners"
  >
    <template #activator="{ on }">
      <slot name="activator" :on="on" />
    </template>
    <v-card>
      <!-- <slot name="header"> -->
      <v-toolbar tile dark color="accent" class="sticky flex-grow-0">
        <v-btn icon @click="close">
          <v-icon v-text="'$vuetify.icons.close'" />
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <!--
            When using FormDialog, be sure to use `slotProps.color` on the v-btn color.
            This ensures that the color is 'accent' on desktop and 'white' on mobile.

            Example:
              <template #button="{ color }">
                <v-btn :color="color" ... />
              </template>
        -->
        <slot name="button" :color="'white'">
          <v-toolbar-items v-if="buttonText">
            <v-btn
              text
              :disabled="buttonDisabled"
              @click="$emit('click:save')"
              >{{ buttonText }}</v-btn
            >
          </v-toolbar-items>
        </slot>
        <template v-if="$scopedSlots.extension" #extension>
          <slot name="extension" />
        </template>
      </v-toolbar>
      <!-- </slot> -->
      <v-container fluid class="scroll">
        <slot></slot>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<style>
.scroll {
  overflow-y: scroll;
}

.sticky {
  position: sticky !important;
  z-index: 999 !important;
  top: 0 !important;
}
</style>

<script>
/*
fullscreen on mobile
max width of 60% on desktop
mobile has toolbar with back button on left and save button on right
desktop has card actions with cancel and save on botton right
*/
import FormDialogBase from "./FormDialogBase";

export default {
  name: "FormDialogMobile",
  extends: FormDialogBase,
};
</script>
