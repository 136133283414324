<template>
  <v-list-item class="shift-dialog__event-list-item" :class="classes">
    <v-list-item-action
      v-if="!hideDelete"
      :class="[$vuetify.breakpoint.smAndDown && `mr-3`]"
    >
      <v-btn text icon small @click="removeShiftEvent">
        <v-icon v-text="`$vuetify.icons.clear`" />
      </v-btn>
    </v-list-item-action>
    <v-list-item-content
      class="shift-dialog__event-list-item__time mr-6"
      v-text="time"
    />
    <v-list-item-content v-text="event.title" />
  </v-list-item>
</template>
<style>
.shift-dialog__event-list-item__time {
  max-width: 17ch;
}
.schedule-drawer .schedule-dialog__event-list-item--is-in-drawer {
  min-height: 24px;
  max-height: 32px;
}
</style>
<script>
import { format, parseISO } from "date-fns";
export default {
  name: "ShiftDialogEventListItem",
  props: {
    event: {
      type: Object,
      default: () => ({}),
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
  },
  inject: ["isInScheduleDrawer"],
  computed: {
    time() {
      return this.event.startAt && this.event.endAt
        ? `${format(parseISO(this.event.startAt), "h:mma")} - ${format(
            parseISO(this.event.endAt),
            "h:mma"
          )}`
        : "";
    },
    classes() {
      return {
        "pl-0": !this.isInScheduleDrawer,
        "pl-4": this.isInScheduleDrawer,
        caption: this.isInScheduleDrawer,
        dense: this.isInScheduleDrawer,
        "py-0": this.isInScheduleDrawer,
        "schedule-dialog__event-list-item--is-in-drawer":
          this.isInScheduleDrawer,
      };
    },
  },
  methods: {
    removeShiftEvent() {
      this.$emit("delete", { ...this.event });
    },
  },
};
</script>

<style>
.hide {
  opacity: 0;
}
.v-list__tile__content.event-details {
  align-items: flex-end;
}
</style>
