<template>
  <div class="tasks pb-10" data-test-ref="tasks">
    <!-- loading state -->
    <div v-if="$apollo.queries.taskLists.loading" class="fill-height">
      <v-card flat class="pa-0 mb-3">
        <v-col cols="4">
          <v-skeleton-loader type="text" class="px-2 pl-4" />
        </v-col>

        <v-card-text class="py-0">
          <v-slide-y-transition>
            <v-list transition="scale-transition">
              <v-skeleton-loader
                v-for="i in 5"
                :key="i"
                type="list-item-avatar"
                class="px-2 py-0"
              />
            </v-list>
          </v-slide-y-transition>
        </v-card-text>
      </v-card>
    </div>
    <!-- non-loading state -->
    <div v-else class="fill-height">
      <template v-for="list in activeTaskLists">
        <v-hover :key="list.id">
          <v-lazy>
            <TaskListCard
              :key="list.id"
              :task-list="list"
              class="mb-3"
              flat
              @edit="editTask"
            ></TaskListCard>
          </v-lazy>
        </v-hover>
      </template>
    </div>

    <TaskDialogEdit v-model="dialog" :task="selectedTask" />
  </div>
</template>

<script>
import TaskListCard from "./TaskListCard";

import { startOfDay, format, parseISO } from "date-fns";
import { mapMutations } from "vuex";
import { ALL_TASK_LISTS } from "@/graphql/task/queries.gql";
import { TASK_CHECK_UPDATED_SUB } from "@/graphql/task/queries.js";
import TaskDialogEdit from "./TaskDialogEdit";
import { injectActiveEmployee } from "@/mixins/employee";
import { hasRouteDay, hasRouteStore } from "@/mixins/routerParams";
export default {
  name: "TaskList",
  components: {
    TaskListCard,
    TaskDialogEdit,
  },
  mixins: [injectActiveEmployee, hasRouteDay, hasRouteStore],
  data: () => ({
    newTodo: "",
    newTodoIsLoading: false,
    lastActiveCheck: null,
    evalLists: true,
    selectedTask: null,
    dialog: false,
    taskLists: [],
    tasksLoading: false,
  }),
  apollo: {
    taskLists: {
      query: ALL_TASK_LISTS,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          storeId: this.storeId,
          businessDate: this.day,
        };
      },
      update({ taskLists }) {
        return taskLists;
      },
      subscribeToMore: [
        {
          document: TASK_CHECK_UPDATED_SUB,
          variables() {
            return {
              storeId: this.storeId,
              businessDate: this.day,
            };
          },
          skip() {
            // skip subscription if we are not on the current day
            return !this.dayIsToday;
          },
        },
      ],
    },
  },
  computed: {
    // deprecated
    /*
    variables() {
      return {
        storeId: this.storeId,
        businessDate: format(this.bizDate, "YYYY-MM-DD"),
      };
    },
    */
    activeTaskLists() {
      return this.getActiveLists(this.taskLists, this.bizDate);
    },
    computedActiveTasksIsLoading() {
      return this.activeTasksIsLoading();
    },
    // why not use dayDate from routerParams?
    bizDate() {
      // TODO - remove from here, needed in params may only
      return startOfDay(parseISO(this.day));
    },
  },
  watch: {
    dialog(v, o) {
      if (!v) {
        this.selectedTask = null;
      }
    },
  },
  methods: {
    ...mapMutations("ui", ["loading", "loaded"]),
    getActiveLists(taskLists, bizDate) {
      try {
        var lists = taskLists
          .filter((taskList) => {
            const listStartDate = taskList.startDate
              ? parseISO(taskList.startDate)
              : null;
            const listEndDate = taskList.endDate
              ? parseISO(taskList.endDate)
              : null;

            const listStartsAfterBizDate =
              listStartDate && listStartDate > bizDate;
            const listEndsBeforeBizDate = listEndDate && listEndDate < bizDate;

            return (
              !listStartsAfterBizDate &&
              !listEndsBeforeBizDate &&
              this.isActiveToday(taskList, format(bizDate, "iiii")) &&
              taskList.tasks.length > 0
            );
          })
          .sort((a, b) => a.position - b.position);
        return lists;
      } catch (e) {
        console.log("ERROR: " + e);
        return null;
      }
    },
    isActiveToday(item, day) {
      if (item.__typename == "TaskList") {
        var dayDict = {
          Monday: item.monday,
          Tuesday: item.tuesday,
          Wednesday: item.wednesday,
          Thursday: item.thursday,
          Friday: item.friday,
          Saturday: item.saturday,
          Sunday: item.sunday,
        };
        return dayDict[day];
      } else return true;
    },
    editTask(task) {
      this.selectedTask = task;
      this.dialog = true;
    },
  },
};
</script>
