import { get, capitalize } from "lodash";

import {
  parseISO,
  isSameDay,
  isSameYear,
  format,
  startOfToday,
  isValid,
} from "date-fns";
import { timestringToTimestamp } from "@/utils/datetime";
import {
  UPDATE_TODO_CHECK,
  TODO_CLEAR_EMPLOYEE,
} from "@/graphql/todo/mutations.gql";
import { UPDATE_TASK, TASK_CLEAR_EMPLOYEE } from "@/graphql/task/mutations.gql";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import { hasGlobalLoaderAccess } from "@/mixins/ui";
import {
  injectActiveEmployee,
  injectClockedInEmployees,
} from "@/mixins/employee";
import { hasRouteDay } from "@/mixins/routerParams";

export const taskMixins = {
  mixins: [injectActiveEmployee, injectClockedInEmployees, hasRouteDay],
};

export const taskProps = {
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    todo: {
      type: Boolean,
      default: false,
    },
    localLoading: {
      type: Boolean,
      default: false,
    },
  },
};

export const taskData = {
  data: () => ({
    expanded: false,
    tooltipDisabled: true,
  }),
};

export const taskHooks = {
  async mounted() {
    await this.$nextTick();

    const el = this.$refs.taskName;
    if (!el) return;

    this.tooltipDisabled = el.scrollHeight <= 55;
  },
};

export const taskComputedProperties = {
  computed: {
    // Date
    completedAt() {
      if (!!this.todo) {
        return this.task.completedAt;
      } else if (!!this.task.completed) {
        return this.task.completed.completedAt;
      }

      return null;
    },
    // Employee
    employee() {
      if (this.todo) return get(this.task, "employee");
      return get(this.task, "completed.employee");
    },
    type() {
      return this.todo ? "todo" : "task";
    },
    dueAt() {
      let val = this.todo ? get(this.task, "dueAt") : get(this.task, "dueTime");
      if (!val) return null;
      return this.todo
        ? parseISO(val)
        : timestringToTimestamp(val, this.dayDate);
    },
    dueAtDisplay() {
      if (!this.dueAt) {
        var formatted = `Completed ${format(
          parseISO(this.completedAt),
          "MMM d @ h:mm aaa"
        )}`;
        // console.log(formatted, this.completedAt || this.todo.completedAt);
        return formatted;
      }

      let formatString;
      let completedAtString;

      if (isSameDay(this.dueAt, this.bizdate)) {
        formatString = "h:mm aaa";
      } else if (isSameYear(this.dueAt, this.bizdate)) {
        formatString = "MMM d @ h:mm aaa";
      } else {
        formatString = "MMM d, yyyy @ h:mm aaa";
      }

      const dueDateString = format(this.dueAt, formatString);

      if (this.completedAt)
        completedAtString = format(parseISO(this.completedAt), formatString);

      return `Due ${dueDateString}${
        completedAtString ? "<br />Completed " + completedAtString : ""
      }`;
    },
    dynamicTimeColorClass() {
      if (this.isPastDue) return "red--text";
      if (this.completed) return "success--text";
      return null;
    },
    // TODO: handle manager content
    shouldShow() {
      if (!this.todo) return true;

      const managerOnly = get(this.task, "isManagerOnly");
      if (!managerOnly) return true;

      return this.activeEmployeeCan.seeManagerContent;
    },
    showEditMenu() {
      return this.todo && this.activeEmployeeCan.editTask;
    },
    taskId() {
      return `task-list-item-${get(this, "task.id") || get(this, "todo.id")}`;
    },
  },
};
export const taskMethods = {
  mixins: [hasSnackbarAccess, hasGlobalLoaderAccess],
  methods: {
    genVariables(action, { id } = {}) {
      const vars = {
        businessDate: format(
          isValid(this.bizdate) ? this.bizdate : new Date(),
          "yyyy-MM-dd"
        ),
        input: {
          id: this.task.id || this.todo.id,
          employeeId: action === "clear" ? null : id,
          clear: action === "clear" ? true : null,
        },
      };
      if (action === "complete") {
        this.type === "todo"
          ? (vars.input.completed = true)
          : (vars.input.completedAt = new Date());
      }
      if (action === "clear") {
        this.type === "todo"
          ? (vars.input.completed = null)
          : (vars.input.completedAt = null);
      }
      return vars;
    },
    assignTask(employee) {
      this.loading(employee.loadingKey);
      this.$apollo
        .mutate({
          mutation: this.todo ? UPDATE_TODO_CHECK : UPDATE_TASK,
          variables: {
            ...this.genVariables("assign", employee),
            businessDate: this.$route.query.d
              ? this.$route.query.d
              : this.CURRENT_DAY,
          },
        })
        .catch((error) => {
          this.showSnackbar({
            text: `An error occurred while updating the ${this.type}: ${error}`,
          });
        })
        .finally(() => {
          this.loaded(employee.loadingKey);
        });
    },
    completeTask(employee) {
      this.loading(employee.loadingKey);
      this.$apollo
        .mutate({
          mutation: this.todo ? UPDATE_TODO_CHECK : UPDATE_TASK,
          variables: this.genVariables("complete", employee),
        })
        .then((_data) => {
          try {
            this.showSnackbar({
              text: `${capitalize(this.type)} completed by ${
                employee.fullName.split(" ")[0] // first name
              }`,
            });
          } catch (e) {
            this.showSnackbar({
              text: `${capitalize(this.type)} completed successfully`,
            });
          }
        })
        .catch((error) => {
          this.showSnackbar({
            text: `An error occurred while updating the ${this.type}: ${error}`,
          });
        })
        .then(() => {
          this.loaded(employee.loadingKey);
        });
    },
    clearTask({ loadingKey }) {
      this.loading(loadingKey);
      this.$apollo
        .mutate({
          mutation: this.todo ? UPDATE_TODO_CHECK : UPDATE_TASK,
          variables: this.genVariables("clear"),
        })
        .then((_data) => {
          this.showSnackbar({
            text: `${capitalize(this.type)} cleared successfully`,
          });
        })
        .catch((error) => {
          this.showSnackbar({
            text: `An error occurred while updated the ${this.type}: ${error}`,
          });
        })
        .then(() => {
          this.loaded(loadingKey);
        });
    },
    clearEmployee({ loadingKey }) {
      this.loading(loadingKey);

      this.$apollo
        .mutate({
          mutation: this.todo ? TODO_CLEAR_EMPLOYEE : TASK_CLEAR_EMPLOYEE,
          variables: {
            id: this.task.id || this.todo.id,
            businessDate: this.$route.query.d
              ? this.$route.query.d
              : this.CURRENT_DAY,
            date: format(
              isValid(this.bizdate) ? this.bizdate : new Date(),
              "yyyy-MM-dd"
            ),
          },
        })
        .catch((error) => {
          this.showSnackbar({
            text: `An error occurred while unassigning the ${this.type}: ${error}`,
          });
        })
        .then(() => this.loaded(loadingKey));
    },
    toggleExpand() {
      // Guard
      if (this.task.name.length < 60) return;

      this.expanded = !this.expanded;
    },
  },
};

export const canOccurDaily = {
  computed: {
    dailySubject() {
      const subject = this.canOccurDailySubject;
      return get(this, subject);
    },
    dailyDays() {
      return [
        {
          name: "sunday",
          short: "Su",
          value: get(this, "dailySubject.sunday"),
        },
        { name: "monday", short: "M", value: get(this, "dailySubject.monday") },
        {
          name: "tuesday",
          short: "Tu",
          value: get(this, "dailySubject.tuesday"),
        },
        {
          name: "wednesday",
          short: "W",
          value: get(this, "dailySubject.wednesday"),
        },
        {
          name: "thursday",
          short: "Th",
          value: get(this, "dailySubject.thursday"),
        },
        { name: "friday", short: "F", value: get(this, "dailySubject.friday") },
        {
          name: "saturday",
          short: "Sa",
          value: get(this, "dailySubject.saturday"),
        },
      ];
    },
    occursEveryday() {
      if (!this.dailySubject) return false;

      return this.dailyDays.map(({ value }) => value).every(Boolean);
    },
    occursNever() {
      if (!this.dailySubject) return false;

      return !this.dailyDays.map(({ value }) => value).some(Boolean);
    },
    occursText() {
      if (!this.dailySubject) return "";
      if (this.occursEveryday) return "Daily";
      if (this.occursNever) return "No days selected";

      return this.dailyDays
        .filter(({ value }) => value)
        .map(({ short }) => short)
        .join(", ");
    },
    bizdate() {
      return parseISO(this.$store.getters["bizdate/getBizDate"]);
    },
  },
};

export const canOccurDailyFn = (subject) => ({
  computed: {
    dailySubject() {
      return get(this, subject);
    },
    dailyDays() {
      return [
        {
          name: "sunday",
          short: "Su",
          value: get(this, "dailySubject.sunday"),
        },
        { name: "monday", short: "M", value: get(this, "dailySubject.monday") },
        {
          name: "tuesday",
          short: "Tu",
          value: get(this, "dailySubject.tuesday"),
        },
        {
          name: "wednesday",
          short: "W",
          value: get(this, "dailySubject.wednesday"),
        },
        {
          name: "thursday",
          short: "Th",
          value: get(this, "dailySubject.thursday"),
        },
        { name: "friday", short: "F", value: get(this, "dailySubject.friday") },
        {
          name: "saturday",
          short: "Sa",
          value: get(this, "dailySubject.saturday"),
        },
      ];
    },
    occursEveryday() {
      if (!this.dailySubject) return false;

      return this.dailyDays.map(({ value }) => value).every(Boolean);
    },
    occursNever() {
      if (!this.dailySubject) return false;

      return !this.dailyDays.map(({ value }) => value).some(Boolean);
    },
    occursText() {
      if (!this.dailySubject) return "";
      if (this.occursEveryday) return "Daily";
      if (this.occursNever) return "No days selected";

      return this.dailyDays
        .filter(({ value }) => value)
        .map(({ short }) => short)
        .join(", ");
    },
  },
});
