<template>
  <!-- TODO: handle manager content -->
  <v-list-item
    v-if="shouldShow && isStarted"
    :id="taskId"
    :class="`rai-task__incomplete pa-0 ${
      employeeChange ? 'employee-change' : ''
    }`"
    :ripple="false"
    :data-test-ref="todo ? `task-list__item--todo` : `task-list__item`"
  >
    <Whodunnit
      :employees="employees"
      :employee="employee"
      :completed="completed"
      :completed-at="completedAt"
      :active-employee="{ id: activeEmployeeId }"
      @assign="assignEmployee"
      @complete="completeTask"
      @clear="clearTask"
      @clear-employee="clearEmployee"
    />
    <v-tooltip
      :attach="`#${taskId}`"
      content-class="task-list__item__tooltip"
      :disabled="tooltipDisabled"
      open-delay="300"
    >
      <template #activator="{ on }">
        <v-list-item-content class="ml-2" v-on="on">
          <v-list-item-title
            ref="taskName"
            :class="`body-2 ${!!dueAt ? 'mt-1' : ''} ${
              pastDue ? 'red--text text--darken-1' : 'text--primary'
            } multiline-ellipsis`"
            data-test-ref="task-list__item__name"
            v-text="task.name"
          />
          <v-list-item-subtitle
            v-if="!!dueAt"
            :class="`${
              pastDue ? 'red--text text--lighten-2' : 'text--disabled'
            } caption mb-n3`"
            >{{ formattedDueAt }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </template>
      <span v-text="task.name" />
    </v-tooltip>
    <!-- <v-list-item-action
      v-if="dueAt && !shouldDisplayCounter"
      data-test-ref="task-list__item__due-time"
    >
      <v-tooltip top>
        <template #activator="{ on }">
          <v-icon
            :class="dynamicTimeColorClass"
            v-on="on"
            v-text="`$vuetify.icons.stopwatch`"
          />
        </template>
        <span v-html="dueAtDisplay" />
      </v-tooltip>
    </v-list-item-action>-->
    <v-list-item-action-text v-if="shouldDisplayCounter && !pastDue">
      <span class="rai-data" v-text="dueCounter" />
    </v-list-item-action-text>
    <!-- TODO: handle manager content -->
    <!-- manager only key icon -->
    <v-list-item-action v-if="task.isManagerOnly">
      <v-tooltip top>
        <template #activator="{ on }">
          <v-icon
            size="20"
            color="warning"
            v-on="on"
            v-text="`$vuetify.icons.key`"
          />
        </template>
        <span>Manager Only Task</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import Whodunnit from "@/components/daybook/Whodunnit";
import { get } from "lodash";
import { mapMutations, mapGetters } from "vuex";

import { isBefore, startOfDay, isDate, format } from "date-fns";
import { timestringToTimestamp, duration } from "@/utils/datetime";
import { globalCurrentTime } from "@/mixins/time";
import { hasRouteDay } from "@/mixins/routerParams";
import {
  hasActiveEmployeeIdAccess,
  injectActiveEmployee,
} from "@/mixins/employee";
import {
  taskComputedProperties,
  taskMethods,
  taskProps,
  taskMixins,
  taskData,
  taskHooks,
} from "@/mixins/task";
export default {
  name: "TaskListItemIncomplete",
  components: {
    Whodunnit,
  },
  mixins: [
    globalCurrentTime,
    hasActiveEmployeeIdAccess,
    taskComputedProperties,
    taskMethods,
    taskProps,
    taskMixins,
    taskData,
    taskHooks,
    injectActiveEmployee,
    hasRouteDay,
  ],
  isStartedInterval: null,
  isPastDueInterval: null,
  durationInterval: null,
  data: () => ({
    isPastDue: false,
    isStarted: false,
    duration: null,
    tooltipDisabled: true,
    employeeChange: false,
  }),
  computed: {
    ...mapGetters("bizdate", ["getBizDate"]),
    ...mapGetters("auth", ["getAuth", "isTeamMode", "activeEmployeeId"]),
    // Bool
    completed() {
      if (this.todo) return get(this.task, "completed", false);
      return !!get(this.task, "completed.completedAt");
    },
    today() {
      return startOfDay(new Date());
    },
    dueAt() {
      if (!this.task.dueTime) return null;
      var v = this.task.dueTime.split(":");

      var hour = v[0];
      var min = v[1];

      var d = new Date(this.dayDate);

      d.setHours(hour, min);

      return d;
    },
    formattedDueAt() {
      if (startOfDay(this.dueAt) > startOfDay(new Date()))
        return `Due ${format(this.dueAt, "MMM do, h:mm aaa")}`;
      return `Due ${format(this.dueAt, "h:mm aaa")}`;
    },
    pastDue() {
      if (!this.dueAt || isBefore(this.today, this.dayDate)) return false;
      if (
        (this.duration && this.duration.seconds < 0) ||
        isBefore(this.dayDate, this.today)
      )
        return true;
      return this.CURRENT_TIME > this.dueAt;
    },
    dueCounter() {
      if (this.completed) return null;
      if (!this.dueAt) return null;
      if (!this.duration) return null;

      if (this.duration && this.duration.hours < 1) {
        return `${this.duration.minutes}:${this.duration.seconds
          .toString()
          .padStart(2, "0")}`;
      }
      if (this.duration && this.duration.seconds < 0) {
        return "Past due";
      }
      return null;
    },
    shouldDisplayCounter() {
      return !!this.dueCounter;
    },
    startTime() {
      const start = startOfDay(new Date());
      if (this.todo) return start;
      const time = get(this.task, "startTime");
      return (
        (time && ((isDate(time) && time) || timestringToTimestamp(time))) ||
        start
      );
    },
  },
  watch: {
    startTime(v, o) {
      if (v !== o) {
        return this.resetTimers();
      }
    },
    "task.dueAt": function (v, o) {
      if (v !== o) {
        return this.resetTimers();
      }
    },
    // Set `isStarted` and kick off a timer for CURRENT_TIME things
  },
  created() {
    this.mountTimers();
  },
  destroyed() {
    this.clearTimers();
  },
  methods: {
    isBefore,
    calcDuration() {
      if (!this.dueAt) return null;
      if (this.isPastDue) return null;
      return duration(this.CURRENT_TIME, this.dueAt);
    },
    mountTimers() {
      this.isStarted = isBefore(this.startTime, new Date());
      // Start an interval to check every second if the task has started.
      // If so, clear the interval. We're using this instead of `computed` to avoid
      // unecessary updates
      if (!this.isStarted) {
        this.$options.isStartedInterval = setInterval(() => {
          this.isStarted = isBefore(this.startTime, this.CURRENT_TIME);

          // clear the interval when necessary
          if (this.isStarted) {
            clearInterval(this.$options.isStartedInterval);
          }
        }, 5000);
      }
      if (this.dueAt) {
        if (!this.isPastDue) {
          this.$options.isPastDueInterval = setInterval(() => {
            this.isPastDue = this.pastDue;

            // clear the interval when necessary
            if (this.isPastDue) {
              clearInterval(this.$options.isPastDueInterval);
            }
          }, 1000);
        }
      }
      if (this.dueAt) {
        if (!this.duration) {
          this.$options.durationInterval = setInterval(() => {
            this.duration = this.calcDuration();

            // clear the interval when necessary
            if (!this.duration) {
              clearInterval(this.$options.durationInterval);
            }
          }, 1000);
        }
      }
    },
    clearTimers() {
      if (this.$options.isStartedInterval)
        clearInterval(this.$options.isStartedInterval);
      if (this.$options.isPastDueInterval)
        clearInterval(this.$options.isPastDueInterval);
      if (this.$options.durationInterval)
        clearInterval(this.$options.durationInterval);
    },
    resetTimers() {
      this.clearTimers();
      this.isPastDue = false;
      this.isStarted = false;
      this.duration = null;
      this.mountTimers();
    },
    async assignEmployee(e) {
      var self = this;
      this.employeeChange = e.id === this.activeEmployeeId ? false : true;
      this.assignTask(e);
    },
  },
};
</script>
<style>
@keyframes moveOffscreen {
  from {
    opacity: 1;
  }
  50% {
    transform: translateX(500px);
    opacity: 1;
  }
  to {
    transform: translate(500px, 500px);
    opacity: 0;
  }
}
.task-list__item__tooltip {
  top: -3ch !important;
}
.employee-change {
  animation: moveOffscreen 450ms;
}
</style>
