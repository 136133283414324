import { omit } from "lodash";

import { isBefore, parseISO } from "date-fns";

// Handler for the `kpiUpdated` subscription event
export const handleKpiUpdated = (previousResult, payload) => {
  // Guards
  // Return `previousResult` if `payload` is falsy
  if (!payload) return previousResult;
  // If the buy is not in the cache, just return `previousResult`
  let existingKpiIndex = previousResult.kpis.findIndex(
    (kpi) => kpi.id === payload.id
  );
  if (existingKpiIndex < 0) {
    return previousResult;
  }
  // If we've made it this far, we found the existing kpi. Update `existingKpi`
  // with `payload`, as long as `payload.updatedAt` comes after
  // `existingKpi.updatedAt`. Then, update the `previousResult.kpis` array.
  const existingKpi = previousResult.kpis[existingBuyIndex];
  if (payloadPrecedesCache(payload, existingKpi)) return previousResult;

  previousResult.buys.splice(existingKpiIndex, 1, {
    ...existingKpi,
    ...payload,
  });
  // Return the updated data
  return {
    ...previousResult,
  };
};

// Private
const payloadPrecedesCache = ({ updatedAt: payload }, { updatedAt: cache }) => {
  const valueToDate = (time) =>
    (typeof time === "string" && parseISO(time)) || time;
  return isBefore(valueToDate(payload), valueToDate(cache));
};
