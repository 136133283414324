<template>
  <component
    :is="dynamicComponent"
    :todo="todo"
    :task="task"
    @edit="(t) => $emit('edit', t)"
    @delete="(t) => $emit('delete', t)"
  />
</template>

<script>
import get from "lodash/get";
import Complete from "./TaskListItemComplete.vue";
import Incomplete from "./TaskListItemIncomplete.vue";
export default {
  name: "TaskListItem",
  components: {
    Complete,
    Incomplete,
  },
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
    employees: {
      type: Array,
      default: null,
    },
    todo: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isComplete() {
      return (
        (this.todo && get(this.task, "completed")) ||
        !!get(this.task, "completed.completedAt")
      );
    },
    dynamicComponent() {
      return this.isComplete ? "Complete" : "Incomplete";
    },
  },
};
</script>

<style></style>
