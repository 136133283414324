<script>
import Schedule from "@/components/daybook/Schedule";
export default {
  name: "ShiftsMobile",
  components: {
    Schedule,
  },
};
</script>
<template>
  <v-container fluid>
    <Schedule />
    <slot />
  </v-container>
</template>
