<template>
  <v-hover #default="{ hover }">
    <!-- TODO: handle manager content -->
    <v-list-item
      v-if="shouldShow && isStarted"
      :id="taskId"
      :class="`rai-task__incomplete pa-0 py-1`"
      elevation="12"
      :ripple="false"
      data-testid="todoListItem"
    >
      <whodunnit
        :employees="employees"
        :employee="todo.employee"
        :completed="completed"
        :completed-at="completedAt"
        :active-employee="{ id: activeEmployeeId }"
        @assign="handleTaskAssignment($event, todo)"
        @complete="completeTask"
        @clear="clearTask"
        @clear-employee="clearEmployee"
      />
      <v-tooltip
        :attach="`#${taskId}`"
        content-class="task-list__item__tooltip"
        :disabled="tooltipDisabled"
        open-delay="300"
      >
        <template #activator="{ on }">
          <v-list-item-content class="ml-2" v-on="on">
            <v-list-item-title
              ref="taskName"
              :class="{
                'body-2': true,
                'mt-1': !!dueAt,
                'pr-1': hover,
                'pr-8': !hover,
                'red--text text--darken-1': pastDue,
                'text--primary': !pastDue,
                'multiline-ellipsis': true,
              }"
              data-test-ref="task-list__item__name"
              v-text="todo.name"
            />
            <v-list-item-subtitle
              v-if="!!dueAt"
              :class="{
                'red--text text--lighten-2': pastDue,
                'text--disabled': !pastDue,
                'caption mb-n3': true,
              }"
              >{{ dueLabel }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <span v-text="todo.name" />
      </v-tooltip>
      <v-list-item-action
        v-if="dueAt && !shouldDisplayCounter"
        data-test-ref="task-list__item__due-time"
      >
        <!-- <v-tooltip top>
          <template #activator="{ on }">

          </template>
          <span v-html="dueAtDisplay" />
        </v-tooltip>-->
      </v-list-item-action>
      <v-list-item-action-text v-else-if="isPastDue">
        <v-tooltip top>
          <template #activator="{ on }">
            <span
              :class="dynamicTimeColorClass"
              v-on="on"
              v-text="`Past due`"
            />
          </template>
          <span v-text="dueAtDisplay" />
        </v-tooltip>
      </v-list-item-action-text>
      <!-- <v-list-item-action-text
        v-else-if="shouldDisplayCounter"
        class="pa-0 ma-0"
      >
        <span class="rai-data" v-text="dueCounter" />
      </v-list-item-action-text>-->
      <!-- manager only key icon -->
      <!-- TODO: handle manager content -->
      <v-list-item-action v-if="todo.isManagerOnly">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-icon
              size="20"
              color="warning"
              v-on="on"
              v-text="`$vuetify.icons.key`"
            />
          </template>
          <span>Manager Only Task</span>
        </v-tooltip>
      </v-list-item-action>
      <!-- Overflow menu -->
      <v-list-item-action v-if="todo && localLoading">
        <v-progress-circular indeterminate />
      </v-list-item-action>
      <v-list-item-action
        v-else-if="showEditMenu"
        v-show="hover"
        class="pa-0 ma-0"
      >
        <v-menu :disabled="dateIsPast">
          <template #activator="{ on }">
            <v-icon
              size="20"
              :color="dateIsPast ? 'grey lighten-2' : 'grey'"
              :disabled="dateIsPast"
              v-on="on"
              v-text="`$vuetify.icons.more`"
            />
          </template>
          <v-list>
            <v-list-item @click="$emit('edit', todo)">
              <v-list-item-content v-text="`Edit`" />
            </v-list-item>
            <v-list-item
              v-if="activeEmployeeCan.deleteTask"
              @click="$emit('delete', todo)"
            >
              <v-list-item-content v-text="`Delete`" />
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>
    </v-list-item>
  </v-hover>
</template>

<script>
import Whodunnit from "@/components/daybook/Whodunnit";
import { get } from "lodash";
import { mapMutations, mapGetters } from "vuex";
// TODO date-fns to v2
import {
  isBefore,
  startOfDay,
  isEqual,
  endOfDay,
  parseJSON,
  differenceInDays,
  setSeconds,
  setMilliseconds,
} from "date-fns";
import { duration, relativeDateTime } from "@/utils/datetime";
import { globalCurrentTime } from "@/mixins/time";
import {
  hasActiveEmployeeIdAccess,
  injectActiveEmployee,
} from "@/mixins/employee";
import { hasRouteDay } from "@/mixins/routerParams";
import {
  taskComputedProperties,
  taskMethods,
  taskProps,
  taskMixins,
  taskData,
  taskHooks,
} from "@/mixins/task";
export default {
  name: "IncompleteTodo",
  components: {
    Whodunnit,
  },
  mixins: [
    globalCurrentTime,
    hasActiveEmployeeIdAccess,
    taskComputedProperties,
    taskMethods,
    taskProps,
    taskMixins,
    taskData,
    taskHooks,
    injectActiveEmployee,
    hasRouteDay,
  ],
  isStartedInterval: null,
  isPastDueInterval: null,
  durationInterval: null,
  props: {
    todo: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isPastDue: false,
    isStarted: false,
    duration: null,
    tooltipDisabled: true,
  }),
  computed: {
    ...mapGetters("bizdate", ["getBizDate"]),
    ...mapGetters("auth", ["getAuth", "isTeamMode"]),
    // Bool
    completed() {
      return get(this.todo, "completed", false);
    },
    dateIsPast() {
      return this.dayDate < startOfDay(new Date());
    },
    dueCounter() {
      if (this.completed) return null;
      if (!this.dueAt) return null;
      if (!this.duration) return null;

      if (this.duration && this.duration.hours < 1) {
        return `${this.duration.minutes}:${this.duration.seconds
          .toString()
          .padStart(2, "0")}`;
      }
      if (this.duration && this.duration.seconds < 0) {
        return "Past due";
      }
      return null;
    },
    dueAt() {
      if (!this.todo.dueAt) return null;
      return new Date(this.todo.dueAt);
    },
    showDueTime() {
      return !isEqual(
        setSeconds(setMilliseconds(endOfDay(parseJSON(this.dueAt)), 0), 0),
        setSeconds(setMilliseconds(parseJSON(this.dueAt), 0), 0)
      );
    },
    daysOverdue() {
      return differenceInDays(
        startOfDay(this.CURRENT_TIME),
        startOfDay(this.dueAt)
      );
    },
    formattedDueAt() {
      return `Due ${relativeDateTime(this.dueAt, undefined, this.showDueTime)}`;
    },
    formattedOverdue() {
      return this.daysOverdue < 2
        ? "Overdue"
        : `Overdue ${this.daysOverdue} days`;
    },
    dueLabel() {
      return this.pastDue ? this.formattedOverdue : this.formattedDueAt;
    },
    pastDue() {
      if (!this.dueAt) return false;
      return this.CURRENT_TIME > this.dueAt;
    },
    shouldDisplayCounter() {
      return !!this.dueCounter;
    },
    startTime() {
      const start = startOfDay(new Date());
      return start;
    },
    deleteLoadingKey() {
      return `delete-todo-${this.todo.id}`;
    },
  },
  watch: {
    startTime(v, o) {
      if (v !== o) {
        return this.resetTimers();
      }
    },
    "todo.dueAt": function (v, o) {
      if (v !== o) {
        return this.resetTimers();
      }
    },
    // Set `isStarted` and kick off a timer for CURRENT_TIME things
  },
  created() {
    this.mountTimers();
  },
  destroyed() {
    this.clearTimers();
  },
  methods: {
    isBefore,
    handleTaskAssignment(emp, todo) {
      var elementId = `task-list-item-${todo.id}`;
      var listItem = document.getElementById(elementId);
      // listItem.classList.push("raise-anim");

      this.assignTask(emp);
    },
    // pastDue() {
    //   return new Date() > this.dueAt;
    //   // if (!this.dueAt) return false;
    //   // if (this.duration && this.duration.seconds < 0) return true;
    //   // return isBefore(this.dueAt, this.completedAt || this.CURRENT_TIME);
    // },
    calcDuration() {
      if (!this.dueAt) return null;
      if (this.isPastDue) return null;
      return duration(this.CURRENT_TIME, this.dueAt);
    },
    mountTimers() {
      this.isStarted = isBefore(this.startTime, new Date());
      // Start an interval to check every second if the task has started.
      // If so, clear the interval. We're using this instead of `computed` to avoid
      // unecessary updates
      if (!this.isStarted) {
        this.$options.isStartedInterval = setInterval(() => {
          this.isStarted = isBefore(this.startTime, this.CURRENT_TIME);

          // clear the interval when necessary
          if (this.isStarted) {
            clearInterval(this.$options.isStartedInterval);
          }
        }, 5000);
      }
      if (this.dueAt) {
        if (!this.isPastDue) {
          this.$options.isPastDueInterval = setInterval(() => {
            this.isPastDue = this.pastDue;

            // clear the interval when necessary
            if (this.isPastDue) {
              clearInterval(this.$options.isPastDueInterval);
            }
          }, 1000);
        }
      }
      if (this.dueAt) {
        if (!this.duration) {
          this.$options.durationInterval = setInterval(() => {
            this.duration = this.calcDuration();

            // clear the interval when necessary
            if (!this.duration) {
              clearInterval(this.$options.durationInterval);
            }
          }, 1000);
        }
      }
    },
    clearTimers() {
      if (this.$options.isStartedInterval)
        clearInterval(this.$options.isStartedInterval);
      if (this.$options.isPastDueInterval)
        clearInterval(this.$options.isPastDueInterval);
      if (this.$options.durationInterval)
        clearInterval(this.$options.durationInterval);
    },
    resetTimers() {
      this.clearTimers();
      this.isPastDue = false;
      this.isStarted = false;
      this.duration = null;
      this.mountTimers();
    },
  },
};
</script>
<style>
.task-list__item__tooltip {
  top: -3ch !important;
}
.rai-task__incomplete {
  transition: 0.5s;
  transition-delay: 0;
}
</style>
