<template>
  <FormDialog
    v-model="dialog"
    title="Edit this task"
    @click:secondary="dialog = false"
  >
    <template #button="{ color }">
      <v-btn
        :loading="isLoading(loadingKey)"
        text
        :disabled="disabled"
        :color="color"
        @click="updateTask"
        v-text="`Update task`"
      />
    </template>
    <v-container>
      <v-form @submit.prevent="updateTask">
        <v-row class="row align-center" no-gutters>
          <span class="shrink">Assign an employee</span>
          <EmployeeSelect
            v-model="employee"
            :clearable="activeEmployeeCan.editTask"
            :employees="employees"
            :hide-search="false"
            @clear="onEmployeeClear"
          />
        </v-row>
        <v-row no-gutters>
          <v-text-field
            v-model="name"
            name="name"
            label="Task description"
            autofocus
            clearable
            autocomplete="nottoday"
          />
        </v-row>
        <v-row no-gutters>
          <RaiDatePicker
            v-model="startDate"
            label="Start date"
            hint="When should this task show up?"
            persistent-hint
          />
        </v-row>
        <v-row class="mt-4" no-gutters>
          <v-col cols="6" class="py-0">
            <RaiDatePicker v-model="dueDate" label="Due date" />
          </v-col>
          <v-col>
            <v-text-field
              v-model="dueTime"
              :input-value="dueTimeInput"
              class="ml-4"
              width="100px"
              label="Due time"
              hint="1p, 1:00 pm, and 13:00 are all valid formats"
              @blur="onTimeChange"
              @keyup.enter="onTimeChange"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-checkbox v-model="isManagerOnly" label="Manager only" />
        </v-row>
      </v-form>
    </v-container>
  </FormDialog>
</template>

<script>
import EmployeeSelect from "@/components/util/RaiEmployeeSelect";
import { get, pickBy } from "lodash";
import { mapGetters } from "vuex";

import { format, isDate, endOfDay, parseISO } from "date-fns";
import { hasSnackbarAccess } from "@/mixins/snackbar";
import RaiDatePicker from "@/components/util/RaiDatePicker";
import { injectActiveEmployee } from "@/mixins/employee";
import { hasGlobalLoaderAccess } from "@/mixins/ui";
import FormDialog from "@/components/core/FormDialogFn";
import { parse as timeParse } from "@/utils/timelanguage";
import { UPDATE_TODO } from "@/graphql/todo/mutations.gql";

export default {
  name: "TaskDialogEdit",
  components: {
    EmployeeSelect,
    RaiDatePicker,
    FormDialog,
  },
  mixins: [hasSnackbarAccess, injectActiveEmployee, hasGlobalLoaderAccess],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    task: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      employee: null,
      name: "",
      isManagerOnly: false,
      loadingKey: "edit-task",
      valid: false,
      dueTime: null,
      dueDate: null,
      localStartDate: null,
      isDifferentDay: false,
    };
  },
  computed: {
    ...mapGetters("bizdate", ["getBizDate"]),
    dialog: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    titleText() {
      if (get(this.employee, "fullName"))
        return `Add a task for ${this.employee.fullName}`;
      return "Add a task";
    },
    businessDate() {
      return format(new Date(), "yyyy-MM-dd");
    },
    variables() {
      // Only send keys which have changed
      // const input = pickBy()
      const input = pickBy(
        {
          id: this.task.id,
          dueAt: this.changes.dueAt && this.computedDueTime,
          businessDate: this.changes.businessDate && this.startDate,
          employeeId: this.changes.employee && this.employee.id,
          name: this.changes.name && this.name,
        },
        (v, _k) => v
      );
      if (this.changes.isManagerOnly) {
        input.isManagerOnly = this.isManagerOnly;
      }
      return { input };
    },
    startDate: {
      get() {
        return this.localStartDate || format(new Date(), "yyyy-MM-dd");
      },
      set(v) {
        this.localStartDate = v;
      },
    },
    computedDueTime() {
      if (!this.dueDate) return null;

      return timeParse(
        this.dueTime || format(endOfDay(parseISO(this.dueDate)), "h:mma"),
        this.dueDate
      ).toJSON();
    },
    changes() {
      const name = get(this.task, "name") !== this.name;
      const employee =
        get(this.task, "employee.id") !== get(this.employee, "id");
      const isManagerOnly =
        get(this.task, "isManagerOnly") !== this.isManagerOnly;
      const taskDueAt = get(this.task, "dueAt", null);
      const dueAt =
        (taskDueAt && format(parseISO(this.task.dueAt), "yyyy-MM-dd")) !==
          (this.dueDate && format(parseISO(this.dueDate), "yyyy-MM-dd")) ||
        ((taskDueAt && format(parseISO(this.task.dueAt), "h:mma")) !==
          this.dueTime &&
          this.dueTime);
      const businessDate = get(this.task, "businessDate") !== this.startDate;

      return {
        name,
        employee,
        isManagerOnly,
        dueAt,
        businessDate,
      };
    },
    requiredFields() {
      return this.name && this.name.length > 0 && !!this.businessDate;
    },
    disabled() {
      if (!this.requiredFields) return true;
      if (Object.values(this.changes).filter((e) => e).length === 0)
        return true;
      return false;
    },
    dueTimeInput() {
      return (
        (isDate(this.dueTime) && format(parseISO(this.dueTime), "h:mma")) ||
        null
      );
    },
  },
  watch: {
    name(v) {
      if (v && v.length > 0) {
        return (this.valid = true);
      }
      this.valid = false;
    },
    dialog(v) {
      if (!v) {
        setTimeout(this.resetFields, 300);
      }
    },
    task(v, o) {
      // Didn't have a task. Now we do
      if (!o && v) {
        this.employee = get(this.task, "employee");
        this.name = get(this.task, "name");
        this.isManagerOnly = get(this.task, "isManagerOnly");
        this.dueTime =
          get(this.task, "dueAt") && format(parseISO(this.task.dueAt), "h:mma");
        this.dueDate =
          get(this.task, "dueAt") &&
          format(parseISO(this.task.dueAt), "yyyy-MM-dd");
        this.startDate = get(this.task, "businessDate");
      }
      // Had a task, but no longer do
      // if (!v && o) {

      // }
    },
  },
  methods: {
    isDate,
    format,
    updateTask() {
      if (!Object.values(this.changes).some((e) => e)) return false;

      this.loading(this.loadingKey);
      this.$apollo
        .mutate({
          mutation: UPDATE_TODO,
          variables: this.variables,
        })
        .then(() => true)
        .catch((error) => {
          this.showSnackbar({
            text: `An error occurred while updating the todo: ${error}`,
          });
          return false;
        })
        .then((success) => {
          this.loaded(this.loadingKey);
          if (success) {
            this.dialog = false;
            this.showSnackbar({
              text: "Todo updated successfully",
            });
          }
        });
    },
    resetFields() {
      this.name = "";
      this.employee = null;
      this.isManagerOnly = false;
      this.valid = false;
      this.dueTime = null;
      this.dueDate = null;
      this.startDate = null;
      this.isDifferentDay = false;
      this.loaded(this.loadingKey);
    },
    onEmployeeClear() {
      this.employee = undefined;
    },
    onTimeChange() {
      this.dueTime =
        (this.dueTime && format(parseISO(this.computedDueTime), "h:mma")) ||
        null;
    },
  },
};
</script>
