<script>
export default {
  name: "MobileFab",
  props: {
    // Expects a route object
    // ex: { name: "newNoteDialog" }
    to: {
      type: [Object, String],
      default: () => ({}),
      required: true,
    },
  },
};
</script>

<template>
  <v-fab-transition>
    <v-btn fab fixed color="primary" bottom right :to="to">
      <v-icon>$plus</v-icon>
    </v-btn>
  </v-fab-transition>
</template>
