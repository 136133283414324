<template>
  <v-list-item
    class="schedule-list-item"
    :class="[hover && `schedule-list-item--is-hover`]"
    v-bind="$attrs"
    v-on="$listeners"
    @mouseenter.native="onMouseEnter"
    @mouseleave.native="onMouseLeave"
  >
    <EmployeeAvatar
      :employee="employee"
      :hide-status="!shift.store.usesExternalScheduler"
      :style="shift.color ? `border: 3px solid #${shift.color} !important` : ''"
      :size="shift.color ? '34px' : '40px'"
      list-item
    />
    <v-list-item-content class="schedule-list-item__detail">
      <v-list-item-title class="text-body-2 px-0">
        <v-tooltip v-if="name && name.length > 17" top>
          <template #activator="{ on }">
            <span
              class="schedule-list-item__detail__name"
              v-on="on"
              v-text="name"
            />
          </template>
          <span v-text="name" />
        </v-tooltip>
        <span v-else class="schedule-list-item__detail__name" v-text="name" />
        <span
          class="text--primary"
          style="font-size: 0.75em; float: right"
          v-text="timeRange"
        />
      </v-list-item-title>
      <v-tooltip v-if="notes && notes.length > 35" top>
        <template #activator="{ on }">
          <v-list-item-subtitle
            class="text-caption text--secondary"
            style="line-height: 1.25em; max-width: 35ch"
            v-on="on"
            v-text="notes"
          />
        </template>
        <span v-text="notes" />
      </v-tooltip>
      <v-list-item-subtitle
        v-else
        class="text-caption text--secondary"
        style="line-height: 1.25em; max-width: 30ch"
        v-text="notes"
      />
    </v-list-item-content>
    <v-list-item-action
      v-show="hover || $vuetify.breakpoint.smAndDown"
      class="mr-0 ml-1"
    >
      <VxMoreMenu x-small left attach-to-parent :disabled="dayIsPast">
        <v-list-item
          :to="{ name: 'editShiftDialog', params: { shiftId: shift.id } }"
          v-text="`Edit`"
        />
      </VxMoreMenu>
    </v-list-item-action>
  </v-list-item>
</template>
<style lang="scss">
.schedule-list-item__detail {
  margin-right: 24px;
}
.schedule-list-item--is-hover .schedule-list-item__detail:not(:last-child) {
  margin-right: 8px;
}
.schedule-list-item__detail:not(:last-child) {
  margin-right: 36px;
}
.schedule-list-item__detail__name {
  display: inline-block;
  min-width: 0%;
  max-width: 17ch;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<script>
import Base from "./Base";
import VxMoreMenu from "@/components/vx/VxMoreMenu";
import EmployeeAvatar from "@/components/employees/EmployeeAvatar.vue";
import { hasRouteDay } from "@/mixins/routerParams";

export default {
  components: { VxMoreMenu, EmployeeAvatar },
  extends: Base,
  mixins: [hasRouteDay],
  data: () => ({
    hover: false,
  }),
  methods: {
    onMouseEnter() {
      this.$vuetify.breakpoint.mdAndUp && (this.hover = true);
    },
    onMouseLeave() {
      this.$vuetify.breakpoint.mdAndUp && (this.hover = false);
    },
  },
};
</script>
