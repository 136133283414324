<template>
  <v-container class="pa-0 shift-dialog__add-event">
    <v-row align="center" no-gutters>
      <v-text-field
        v-model="title"
        name="description"
        label="Description"
        autocomplete="nottoday"
        class="shift-dialog__add-event__description"
        :class="{
          'mr-4': $vuetify.breakpoint.mdAndUp,
          'shift-dialog__add-event__description--shrink':
            $vuetify.breakpoint.mdAndUp,
        }"
        clearable
      />
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <rai-time-picker-text
          v-model="startAt"
          class="mr-4"
          :reference-date="referenceDate"
          label="Start"
        />
        <rai-time-picker-text
          v-model="endAt"
          :reference-date="referenceDate"
          label="End"
        />
        <v-btn rounded small text :disabled="!valid" @click="onAddClick"
          >Add</v-btn
        >
      </template>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown" align="center" no-gutters>
      <rai-time-picker-text
        v-model="startAt"
        class="mr-4"
        :reference-date="referenceDate"
        label="Start"
      />
      <rai-time-picker-text
        v-model="endAt"
        :reference-date="referenceDate"
        label="End"
      />
      <v-btn rounded small text :disabled="!valid" @click="onAddClick"
        >Add</v-btn
      >
    </v-row>
  </v-container>
</template>
<style>
.shift-dialog__add-event__description--shrink {
  max-width: 20ch;
}
</style>
<script>
import RaiTimePickerText from "@/components/util/RaiTimePickerText";

import { isWithinInterval, setDate, parseISO } from "date-fns";

export default {
  components: { RaiTimePickerText },
  props: {
    referenceDate: {
      type: [Date, String],
      default: new Date(),
    },
    min: {
      type: [Date, String],
      required: true,
    },
    max: {
      type: [Date, String],
      required: true,
    },
  },
  data: () => ({
    startAt: undefined,
    endAt: undefined,
    title: "",
  }),
  computed: {
    computedTimes() {
      const times = {
        startAt: this.startAt,
        endAt: this.endAt,
        min: this.min,
        max: this.max,
      };
      return Object.keys(times).reduce((acc, k) => {
        acc[k] = this.toReferenceDate(times[k], this.referenceDate);
        return acc;
      }, times);
    },
    valid() {
      return [
        this.startAt,
        this.endAt,
        this.title,
        isWithinInterval(this.valueToDate(this.computedTimes.startAt), {
          start: this.valueToDate(this.computedTimes.min),
          end: this.valueToDate(this.computedTimes.max),
        }),
        isWithinInterval(this.valueToDate(this.computedTimes.endAt), {
          start: this.valueToDate(this.computedTimes.min),
          end: this.valueToDate(this.computedTimes.max),
        }),
      ].every(Boolean);
    },
  },
  methods: {
    onAddClick() {
      return (
        this.valid &&
        this.$emit("submit", {
          storeId: this.$route.params.storeId,
          startAt: this.startAt,
          endAt: this.endAt,
          title: this.title,
        }) &&
        this.reset()
      );
    },
    reset() {
      this.startAt = undefined;
      this.endAt = undefined;
      this.title = "";
      return true;
    },
    toReferenceDate(date, refDate) {
      return setDate(
        this.valueToDate(date),
        this.valueToDate(refDate).getDate()
      );
    },
    valueToDate(v) {
      return (typeof v === "string" && parseISO(v)) || v;
    },
  },
};
</script>
